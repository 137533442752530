import React from 'react';
import InterfaceDialer from '../../components/Dialer/Dialer';
import { InterfaceHeader } from '../../components';
import HeaderContext from '../../components/Header/headerContext';

import './ConnectPage.scss';

export default function ConnectPage(props: any) {
  return (
    <div className="connect-container">
    <HeaderContext.Provider value={{name:"Connect", company:"This is a test"}}>
      <InterfaceHeader />
    </HeaderContext.Provider>
    <div className="content-wrapper">
      <div className='component-title'>Connect</div>
      <div>
        <InterfaceDialer phoneNumber={props.match.params} />
      </div>
    </div>
    </div>
  );
}
