import React from 'react';
import {
	// Paper,
	Tabs,
	Tab
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import {
	PhonebookTable,
	PhonebookTableFull,
	PhonebookSelect,
	CreateContact,
	Searchbar,

} from './components';
import './Phonebook.scss';
// import logo from "../../../css/images/stockland.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	// faAddressCard,
	faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { InterfaceSidebar } from '../../components';
import { InterfaceHeader } from '../../components';
import { InterfaceLoader } from '../../components';
import { config } from "../../core/config/config";


// Interface Definition
interface IDataContact {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	numberType: string;
	organization: string;
}
interface IProps {
	stylesetting: any,
	user: any
	navbardisplay: boolean;
}

interface IStates {
	queueSelect: any[];
	queueData: any[];
	queueDataMas: any[];
	PhonebookTableData: any[];
	PhonebookDataStore: any[];
	currentTab: number;
	firstName: string,
	lastName: string,
	status: string,
	loaded: boolean
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

export default class Phonebook extends React.Component<IProps, IStates> {
	constructor(props: any) {
		super(props);

		this.state = {
			queueSelect: [],
			queueData: [],
			queueDataMas: [],
			PhonebookTableData: [],
			PhonebookDataStore: [],
			currentTab: 0,
			firstName: '',
			lastName: '',
			status: '',
			loaded: false
		};
	}

	public intersSearcType: any = '';

	public managSel = data => {
		const { queueDataMas } = this.state;

		queueDataMas.forEach((el, index) => {
			if (el.name === data) {
				this.setState({
					queueData: el.phoneBook,
				});
			}
		});
	};

	public openCont = (data: IDataContact) => {
		const newContact: IDataContact[] = this.state.PhonebookTableData.map(el => el);
		newContact.unshift(data);
		this.setState({
			PhonebookTableData: newContact,
			PhonebookDataStore: newContact
		})
	}

	public findeAlignment = (data) => {
		const dataInp = data;
		if (dataInp === '' || dataInp === ' ') {
			this.setState({
				PhonebookTableData: this.state.PhonebookDataStore
			})
		} else {
			const resultSearch = this.searchAllRes(
				this.state.PhonebookDataStore,
				dataInp,
				'firstName',
				'lastName',
				'organization'
			);
			this.setState({
				PhonebookTableData: resultSearch
			})
		}
	}

	public searchAllRes = (data: any, str: string, ...arg: string[]) => {

		if (!arg && !str && !data) return false;

		str = str.toLowerCase();
		const masList: any = [];

		for (const item of arg) {
			masList[masList.length] = data.filter(el => {
				let searchStr: string = el[item];
				searchStr = searchStr.toLowerCase();
				return searchStr.includes(str);
			})
		}

		const masListAll: any = [];
		for (const item of masList) {
			if (item) {
				item.forEach(el => masListAll.push(el))
			}
		}

		const result: any = [];

		for (const item of masListAll) {
			let isEl = false;

			for (let i = 0; i <= result.length; i++) {
				if (!result.length) result.push(item);
				if (result[i] && result[i].id === item.id) {
					isEl = true;
					break;
				}
			}

			if (!isEl) {
				result.push(item);
			}
		}

		return result;
	}

	public searchDelay = (event: React.ChangeEvent<HTMLInputElement>) => {
		clearTimeout(this.intersSearcType);
		this.intersSearcType = setTimeout(this.findeAlignment, 500, event.target.value);
	}

	public searchByKeyword = (event: any) => {
		// console.log(event.target.value);
	}

	public componentDidMount() {
		if (sessionStorage.getItem("c_user")) {
			const loaded = this.state.loaded;
			this.setState({
				loaded: true
			})

			// // ----  data for Personal Phonebook this is only temporary
			// fetch(config.AGENTAPI + sessionStorage.getItem("c_user"))
			// 	.then(res => res.json())
			// 	.then((data) => {
			// 		this.setState({ firstName: data["firstName"] });
			// 		this.setState({ lastName: data["lastName"] });
			// 		this.setState({ status: data["status"] });

			// 		const { info, queues } = data;

			// 		const queueSelectMas = queues.map((el) => {
			// 			return el.name
			// 		})

			// 		queues.forEach((queue, index) => {
			// 			queue.phoneBook.forEach((elPh, indexPh) => {
			// 				elPh.fullName = `${elPh.firstName} ${elPh.lastName}`;
			// 				delete elPh.id;
			// 				delete elPh.firstName;
			// 				delete elPh.lastName;
			// 			})
			// 		});

			// 		this.setState({
			// 			// personal phonebook
			// 			PhonebookTableData: info,
			// 			PhonebookDataStore: info,
			// 			// company phonebook
			// 			queueSelect: queueSelectMas,
			// 			queueDataMas: queues,
			// 			queueData: queues[0].phoneBook,
			// 			loaded: !loaded
			// 		});
			// 	})
			// 	.catch(console.log);
		}
	}

	public handleTabChange = (event, newValue) => {
		this.setState({ currentTab: newValue });
	}

	public handleTabIndexChange = (index) => {
		this.setState({ currentTab: index });
	}

	public render() {

		const PhonebookTableData = this.state.PhonebookTableData;
		const { queueSelect, queueData } = this.state;
		const { currentTab } = this.state;
		const loaded = this.state.loaded;

		let styletoadd = ""
		if (!this.props.stylesetting) {
			styletoadd = "content-wrapper-td"
		} else {
			styletoadd = this.props.stylesetting
		}

		return (
			<div className="phonebook-wrapper">
				{!loaded && (<InterfaceLoader />)}


				<InterfaceHeader user={this.props.user} pagename="Phonebook" />

				<div className={styletoadd}>

					<div className="component-title">Phonebook</div>
					<div className="tale-bl-head statusbar">
						{/* <div className="table-header-image">
							<img src={logo} alt="client logo" className="image1" />
						</div> */}
						<div className="bl-agent-data">
							{this.state.firstName} {this.state.lastName}< br />
							Status: {this.state.status}
						</div>
					</div>
					<div className="tale-bl-head contactheader">
						{this.state.currentTab === 0 ? (
							<div className="tabs-left">
								<h2 className="component-title-second">Personal (coming soon)</h2>
							</div>
						) : (
								<div className="tabs-left">
									<h2 className="component-title-second">{this.state.queueData[0].queueName}</h2><PhonebookSelect managSel={this.managSel} queueSelect={queueSelect} />
								</div>
							)}

						<div className="tabs-right">
							<Tabs
								value={currentTab}
								onChange={this.handleTabChange}
								indicatorColor="primary"
								textColor="primary"
								// variant="fullWidth"
								aria-label="full width tabs example"
							>
								<Tab label={<FontAwesomeIcon icon={faUser} size='2x' />} {...a11yProps(0)} />
								{/* <Tab label={<FontAwesomeIcon icon={faUserFriends} size='2x' />} {...a11yProps(1)} /> */}
							</Tabs>
						</div>
					</div>

					<div className="table-big-wrapper">
						<SwipeableViews
							index={currentTab}
							onChangeIndex={this.handleTabIndexChange}
						>
							<section
								className="table-wrap"
								role="tabpanel"
								id='full-width-tabpanel-0'
								hidden={currentTab !== 0}
								aria-labelledby='full-width-tab-0'
							>
								<div className="table-manag__wrap">
									<Searchbar onChange={this.searchByKeyword} />
									<CreateContact openCont={this.openCont} />
								</div>
								<PhonebookTable dataTable={PhonebookTableData} />
							</section>
							<section
								className="table-wrap"
								role="tabpanel"
								id='full-width-tabpanel-1'
								hidden={currentTab !== 1}
								aria-labelledby='full-width-tab-1'
							>
								<div className="table-manag__wrap">
									<Searchbar onChange={this.searchByKeyword} />
									<CreateContact openCont={this.openCont} />
								</div>
								<PhonebookTableFull dataTable={queueData} />
							</section>
						</SwipeableViews>
					</div>
				</div>
				
					{this.props.user.permissions.navigation.active && this.props.navbardisplay && (<nav>
						<InterfaceSidebar user={this.props.user} />
					</nav>
					)}
			</div>

		);
	}
}
