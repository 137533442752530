import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// import FirestarterConfig from './core/config/config';

// Global css
import '../css/global.scss';

// Components

import { InterfaceToolBar } from './components';
import TailoredDashboardsPage from './pages/TailoredDashboards';
import ViewerDashboardsPage from './pages/TailoredDashboards/BoardViewer';
import PhonebookPage from './pages/Phonebook';
import GridPanelPage from './pages/GridPanel';
import ConnectPage from './pages/Connect';
import CallHistoryPage from './pages/CallHistory/CallHistoryPage';
import LoginPage from './pages/Login';
import AdminPage from './pages/Admin';

import './Firestarter.scss';
import { config } from './core/config/config';

interface IProps {

}


interface IStates {
  user_id: any;
  loaded: any;
  userdata: any;
}

export default class Firestarter extends React.Component<IProps, IStates> {
  // private config: FirestarterConfig = new FirestarterConfig();

  private interval: any;

  constructor(props: any) {
    super(props);
    // this.config.loadDefaults();
    this.state = {
      user_id: sessionStorage.getItem("c_user") === null ? undefined : sessionStorage.getItem("c_user"),
      userdata: {},
      loaded: false
    }

  }



  public componentDidMount() {
    this.interval = setInterval(() => {
      const user_id = sessionStorage.getItem("c_user") === null ? undefined : sessionStorage.getItem("c_user");
      if (user_id) {
        this.setState({
          user_id: sessionStorage.getItem("c_user") === null ? undefined : sessionStorage.getItem("c_user"),

        })

        // fetch(
        //   'https://api.jsonbin.io/b/5f39d950af209d1016bcdeb1', {
        //     method: 'GET',
        //     headers: new Headers({
        //      'secret-key': '$2b$10$wHrEQEUK3m4WJ78pezqkg.QpgBpcLVpri3EO3fA2Zq/D.kSFDE9xy',
        //      })
        //   }
        // )
        fetch(config.USERAPI + sessionStorage.getItem("c_user"))
          .then(res => res.json())
          .then(data => {
            
            const loaded = this.state.loaded;

            this.setState({
              userdata: data["configBlob"],
              loaded: !loaded
            });


          })
          .catch(console.log);

        clearInterval(this.interval);
      }
    }
      , 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  public render() {

    const loaded = this.state.loaded; // eslint-disable-line

    return (
      <Router>


        {loaded ? (
          <div className='container'>

              <div className='page'>
              <Route exact path='/connect' component={ConnectPage} />
              <Route exact path='/connect/:phoneNumber' component={ConnectPage} />
              <Route
                exact path='/'
                render={(props) => (
                  <GridPanelPage {...props}  user={this.state.userdata} />
                )}
                />
              <Route
                exact path='/dashboard'
                render={(props) => (
                  <GridPanelPage {...props}  user={this.state.userdata} />
                )}
                />
              <Route
                exact path='/phonebook'
                render={(props) => (
                  <PhonebookPage {...props} stylesetting="content-wrapper-td" user={this.state.userdata} navbardisplay={true} />
                )}
                />
                <Route
                exact path='/callhistory'
                render={(props) => (
                  <CallHistoryPage {...props} stylesetting="content-wrapper-td" user={this.state.userdata} navbardisplay={true}/>
                )}
                />

              <Route
                exact path='/tailoreddashboard'
                render={(props) => (
                  <TailoredDashboardsPage {...props} user={this.state.userdata} navbardisplay={true} />
                )}
                />

                <Route
                  exact path='/tailoreddashboard/:boardID'
                  render={(props) => (
                    <ViewerDashboardsPage {...props} user={this.state.userdata} />
                  )}
                  />

                  <Route
                   exact path='/admin'
                   render={(props) => (
                     <AdminPage {...props} user={this.state.userdata} />
                   )}
                   />

                 <Route
                   exact path='/admin/:setting'
                   render={(props) => (
                     <AdminPage {...props} user={this.state.userdata} />
                   )}
                   />

              </div>

          </div>
          ) : (
            <div className='page'>
              <Route exact path='/*' component={LoginPage} />
            </div>
          )
        }
          <InterfaceToolBar />


        <Route />
      </Router>
    );
  }
}
