import React from 'react';
import  '../../../../css/brand/test/interface/dashboardLock.scss'

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

export default function AddDB (props:any) {
  const {lockStatus} = props
  const {changeLock} = props

  const handleclick = (event: React.MouseEvent<HTMLElement>) => {
      console.log("click");
      changeLock();


  };

  return (
    <div className="dashboard-lock" onClick={handleclick}>
      {lockStatus && (<LockIcon />  )}
        {!lockStatus && (<LockOpenIcon />  )}
    </div>
  );
}
