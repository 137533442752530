import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

export default function PhonebookSelect(props: any) {
	const {managSel, queueSelect} = props

	const [age, setAge] = React.useState(''); // eslint-disable-line
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	    setAnchorEl(event.currentTarget);
	  };

	const handleClose = (event) => {
			const { myValue } = event.currentTarget.dataset;
			console.log(myValue);
			setAge(myValue);
			managSel(myValue);
			setAnchorEl(null);
		};


	const menuOption = queueSelect.map( (el, i) => ( <MenuItem onClick={handleClose} key={el} data-my-value={el}>{el}</MenuItem>))


	return (
		<div className="change-button">

		<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
			Change
		</Button>
		<Menu
 		 id="simple-menu"
 		 anchorEl={anchorEl}
 		 keepMounted
 		 open={Boolean(anchorEl)}
 		 onClose={handleClose}
 	 >
 		 {menuOption}
 	 </Menu>
	 </div>
	);
}
