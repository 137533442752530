import React from 'react';
import {Pie} from 'react-chartjs-2';
import './pieGraph.scss'

const data = {
	labels: [
		'Total',
		'Used',

	],
	datasets: [{
		data: [80, 10],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',

		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',

		]
	}]
};

const options = {
  legend: { display: false }
}

export default props => (
    <div
    className="chart-item"
    key= {props.block._uid}
  >
  <div>
			<div className="chart-internal">
       <Pie
       data={data}
      options={options}
       />
     </div>
		  </div>



    <h2>{props.block.name} </h2>
  </div>
);
