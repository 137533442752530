import React from 'react';
import queries from '../../../core/config/queries.json';
import { config } from '../../../core/config/config';
import elasticsearch from "elasticsearch";

interface IProps {
  dataModule: any
  postProcessor: any
  element: any
  processor: any
  queueid: any
  raw: any

 }

 interface IStates {
   loaded: boolean,
 }

export default class Data extends React.Component<IProps, IStates> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  public componentDidMount() {

    let theQuery= queries[this.props.dataModule];
    theQuery.index = config.INDEXNAME;
    let client = elasticsearch.Client({
      host: config.ELASTICENDPOINT,
    });
    let options = {} as any

    /* console.log("dmt",this.props) */

    if (this.props.dataModule === 'QUEUES-GENERAL') {
        theQuery.query.aggs[1].top_hits.docvalue_fields[0].field = this.props.element;
        theQuery.query.query.bool.filter[0].bool.should[0].match_phrase["QueueID.keyword"] = this.props.queueid;
        theQuery.query.query.bool.filter[1].bool.should[0].match_phrase["QueueID.keyword"] = this.props.queueid;
    }

    if (this.props.dataModule === 'DATE-SEARCH') {
        theQuery.query.aggs[2].aggs[1].top_hits.docvalue_fields[0].field = this.props.element;
        theQuery.query.aggs[2].aggs[1].top_hits.sort[this.props.element] = {order: "desc"};
        theQuery.query.query.bool.filter[1].match_phrase.QueueID = this.props.queueid;

        /* console.log( theQuery.query.query.bool.filter[2].range.create_date.gte ) */
        if (this.props.raw.dateRange.active) {
            if (this.props.raw.dateRange.dateRangeUnit === "days" ) {
              theQuery.query.aggs[2].date_histogram.calendar_interval = "1d"
              theQuery.query.query.bool.filter[2].range.create_date.gte = "now-"+this.props.raw.dateRange.dateRangeValue+"d/d"
              theQuery.query.query.bool.filter[2].range.create_date.lte = "now/d"
              options.time = "days"
            } else {
              theQuery.query.aggs[2].date_histogram.calendar_interval = "1m"
              theQuery.query.query.bool.filter[2].range.create_date.gte = "now-"+this.props.raw.dateRange.dateRangeValue+"m/m"
              theQuery.query.query.bool.filter[2].range.create_date.lte = "now/m"
              options.time = "mins"
            }
        }
    }
    /* console.log("drawer",JSON.stringify(theQuery.query)) */

    client
      .search({
        index: theQuery.index,
        body: theQuery.query
      })
      .then(
        function(body) {
        /* console.log( "query report" + this.props.element, body ); */
        // if (['CONTACTS_IN_QUEUE', 'OLDEST_CONTACT_AGE'].includes(this.props.element)) {
        //   console.log(this.props.element);
        //   console.log(body)
        //   console.log(this.props.element + ' ' + this.props.processor);
        //   console.log(dataProcessors[this.props.processor](body, this.props.element, options))
        // }

        var finalValue = dataProcessors[this.props.processor](body, this.props.element, options);



        this.props.postProcessor({"dValue": finalValue});

        }.bind(this),
        function(error) {
          console.trace(error.message);
        }
      );
  }

  public render() {
    return (
      <div>Loading</div>
    );
  }
}

var dataProcessors = {
  getValue: function (data, token, options) {
    if (data.aggregations[1].hits.hits.length < 1) {
        return "N/A"
    } else {
      return data.aggregations[1].hits.hits[0]._source[token];
    }
  },
  getTime: function (data, token, options) {
    if (data.aggregations[1].hits.hits.length < 1) {
        return "N/A"
    } else {
      var time =  Math.round((data.aggregations[1].hits.hits[0]._source[token])/1000);
      /* var minutes = Math.floor(time / 60);
      var theminute = ("0" + minutes).slice(-2);
      var seconds = time - (minutes * 60);
      var theseconds = ("0" + seconds).slice(-2); */
      return time + "s";
    }
  },
  getTimeFmt: function (data, token, options) {
    if (data.aggregations[1].hits.hits.length < 1) {
        return "N/A"
    } else {
      const milliseconds = data.aggregations[1].hits.hits[0]._source[token];
      const timeMatch = new Date(milliseconds).toISOString().match(/(\d{1,2}([.\:/])\d{1,2}([.\:/])\d{1,2})/g);
      console.log(milliseconds + ' ' + timeMatch)
      return !!timeMatch ? timeMatch[0] : '00:00:00'
    }
  },
  makeGraph: function (data, token, options) {
    const dates : string[] = [];
    const values : string[] = [];
    data.aggregations[2].buckets.map(function(item, i){
      if (options.time === "days") {
        const dateMatch = item.key_as_string.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,2})/g);
        dates.push(!!dateMatch ? dateMatch : '0000-00-00');
      } else {
        const timeMatch = item.key_as_string.match(/(\d{1,2}([.\:/])\d{1,2}([.\:/])\d{1,2})/g);
        dates.push(!!timeMatch ? timeMatch : '00:00:00');
      }

      values.push(item[1].hits.hits[0]._source[token]);
      return null
    })

    const results = {
      "dates" : dates,
      "values" : values
     }
    return results;
  },
  func2: function () { alert('Function 2'); },
};
// execute the one specified in the 'funcToRun' variable:
