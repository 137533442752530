import React from 'react';
// import logo from "../../../css/images/stockland.png";
import cwlogo from "../../../css/images/CloudWave-Logo-01.png";


//import { Link } from 'react-router-dom';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {
//  faQrcode,
  // faUserCog,
//  faPhone,
//  faChalkboardTeacher,
  // faAddressCard,
//  faPhoneSquare,
//} from '@fortawesome/free-solid-svg-icons';



import './Header.scss';


export default function ActionMenu (props:any) {
  let usericon = "https://ui-avatars.com/api/?name=" + props.user.username;

  return (
            <div className='header'>
                  <img src={cwlogo} alt='cloudwave logo' className="header-logo" />

                  <div className="account-details">
                      <div className="account-avatar">
                        <img src={usericon} alt='avatar' />
                      </div>
                      <div className="account-name">
                        {props.user.username}
                        </div>

                  <div>


          </div>
          <span></span>

        </div>
      </div>



    );


}
