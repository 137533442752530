import React from 'react';


export default function UpdateTime (props:any) {
  const {updateTime} = props


  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";


  const t = new Date( updateTime );
  const formatted = ('0' + t.getHours()).slice(-2) + ':' + ('0' + t.getMinutes()).slice(-2) + ':' + ('0' + t.getSeconds()).slice(-2) + ' ' + weekday[t.getDay()];




  return (
    <div className="update-time">
        {formatted}
    </div>
  );
}
