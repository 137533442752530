import React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';

import DashBoardSelect from './Interface/DashBoardSelect';
import ActionMenu from './Interface/actionMenu';
import AddDB from './Interface/addDB';
import EditDB from './Interface/editDB';
import SelectWidget from './Interface/selectWidget';
import EditWidget from './Interface/editWidget';
import LockDashboard from './Interface/lockDashboard';
import UpdateTime from './Interface/updateTime';
import Share from './Interface/share';
import Copy from './Interface/copyDB';


import { ContextObject } from '../../components/userContext';


import './TailoredDashboardsPage.scss';
import '../../../css/global.scss'

import { InterfaceSidebar } from '../../components';
import { InterfaceHeader } from '../../components';
import Components from "./components";

import { InterfaceLoader } from '../../components';
import widgets from '../../core/config/widgets.json';
import { config } from '../../core/config/config';

const ReactGridLayout = WidthProvider(RGL);



interface IProps {
  user: any;
  navbardisplay: boolean;

}

interface IProfile {
  userId: number;
  theme: string;


}

interface IStates {
  loaded: boolean,
  users: any,
  error: any,
  profile: any,
  dashboards: any,
  layout: any,
  currentDashboard: any,
  newDB: boolean,
  editDB: boolean,
  newWidget: boolean,
  lockStatus: boolean,
  share: boolean,
  copy: boolean,
  windowHieght: any
  isDraggable: boolean,
  isResizable: boolean,
  updateTime: number,
  timerBar: any,
  refreshSec: number,
}

var dashboardData;
var interval;



export default class GridPanel extends React.Component<IProps, IStates> {
  private layout: any;
  private columns: number = 0;
  private rowHeight: number = 0;
  private width: number = 0;
  private rows: number = 0;


  divRef = React.createRef<HTMLDivElement>();



  constructor(props: any) {
    super(props);
    this.state = {
      users: [],
      error: false,
      profile: { userId: -1, theme: 'empty' },
      dashboards: [],
      layout: [],
      currentDashboard: 0,
      loaded: false,
      newDB: false,
      editDB: false,
      newWidget: false,
      share: false,
      copy: false,
      lockStatus: false,
      windowHieght: 1000,
      isDraggable: true,
      isResizable: true,
      updateTime: Date.now(),
      timerBar: { "width": "0%" },
      refreshSec: 10
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.resizeWindows = this.resizeWindows.bind(this);
  }



  public addDB = data => {
    console.log("add db", data);
    this.setState({ newDB: false })
    if (data.status) {
      let dashboardNew = {
        "id": "d" + Date.now(),
        "name": data.name,
        "theme": data.theme,
        "backgroundcolor": data.backgroundcolor,
        "permission": "edit",
        "components": {
        },
        "layout": [

        ]
      }
      this.state.dashboards.push(dashboardNew)
      this.setState({ currentDashboard: this.state.dashboards.length - 1 })
      this.writeDB(this.state.dashboards)

    }
  };

  public editDB = data => {
    if (data.status) {
      let dashboards = [this.state.dashboards]
      dashboards[this.state.currentDashboard] = data.data
      this.setState({
        editDB: false,
        dashboards: dashboards
      })
      this.writeDB(this.state.dashboards)
    } else {
      this.setState({ editDB: false })
    }
  };

  public copyDB = data => {
    console.log("before", this.state.dashboards);
    if (data.status) {
      this.state.dashboards.push(data.data)
      this.setState({
        copy: false,
        currentDashboard: this.state.dashboards.length - 1
      })
      console.log(this.state.dashboards);
      this.writeDB(this.state.dashboards)
    } else {
      this.setState({ copy: false })
    }
  };


  public closeShare = () => {
    this.setState({ share: false })
  };


  public managSel = data => {      
      this.setState({ currentDashboard: data})
  };

  public editWidget = event => {

  };

  public changeWidget = data => {
    if (data.delete) {
      delete this.state.dashboards[this.state.currentDashboard].components[data.index];
      this.setState({ currentDashboard: this.state.currentDashboard })
      this.writeDB(this.state.dashboards)
    } else {

      // this.setState(dashboards => { dashboards[this.state.currentDashboard].components[data._uid].push(data) })
      this.state.dashboards[this.state.currentDashboard].components[data._uid] = data
      this.writeDB(this.state.dashboards)
      this.setState({ currentDashboard: this.state.currentDashboard })
    }
  };

  public addWidget = data => {

    if (data.hide) {
      this.setState({ newWidget: false })
    } else {
      this.setState({ newWidget: false })
      let widget = widgets[data]

      var newid = "a" + Date.now()

      let templateJson = {
        "_uid": newid,
        "name": widget.name,
        "type": data,
        "visualisation": widget.visualisations[0],
        "queueid": this.props.user.queues[0].QueueID,
        "queueName": this.props.user.queues[0].name,
        "dataModule": widget.query,
        "element": widget.element,
        "processor": widget.processor,
        "backgroundColor": "#ffffff",
        "threshold": {
          "active": false,
          "warning": {
            "enabled": false,
            "value": "0",
            "calc": "gte"
          },
          "alert": {
            "enabled": false,
            "value": "5",
            "calc": "gte"
          }
        },
        "dateRange": {
          "active": widget.dateRange,
          "dateRangeValue": "5",
          "dateRangeUnit": "days",
        },
        "colors": this.props.user.colors
      }
      if (widget.threshold) {
        templateJson.threshold.active = true;
      }
      if (widget.dateRange) {
        templateJson.dateRange.active = true;
      }

      let existingDB = JSON.parse(JSON.stringify(this.state.dashboards))

      existingDB[this.state.currentDashboard].components[newid] = templateJson;

      let newLayout = {
        i: newid,
        moved: false,
        static: false,
        h: 1,
        w: 1,
        x: 6,
        y: 1
      }
      existingDB[this.state.currentDashboard].layout.push(newLayout)

      this.setState({ dashboards: existingDB })
      this.writeDB(existingDB)
    }




    //pus to dashboards and change state
  };

  public actionMenuSelect = data => {

    if (data === "1") {
      this.setState({ newDB: true })
    }
    if (data === "3") {
      this.setState({ share: true })
    }
    if (data === "4") {
      this.setState({ copy: true })
    }
    if (data === "5") {
      this.setState({ editDB: true })
    }
    if (data === "6") {
      this.setState({ newWidget: true })
    }
    if (data === "7") {
      this.state.dashboards.splice(this.state.currentDashboard, 1);
      this.writeDB(this.state.dashboards)
      this.setState({ currentDashboard: 0 })
    }
  };

  public currentDashboardSelect = index => {    
    this.setState({ currentDashboard: index });
  }

  public lockBoard = () => {

    var lockStatus = this.state.lockStatus
    var isDraggableTemp
    var isResizeableTemp
    if (lockStatus) {
      isDraggableTemp = true
      isResizeableTemp = true
    } else {
      isDraggableTemp = false
      isResizeableTemp = false
    }
    this.setState({
      lockStatus: !lockStatus,
      isDraggable: isDraggableTemp,
      isResizable: isResizeableTemp
    });
  }

  public onLayoutChange(layout) {
    if (layout.length !== 0) {
      let currDashboards = this.state.dashboards
      currDashboards[this.state.currentDashboard].layout = layout
      this.writeDB(currDashboards)
    }
  }

  public resizeWindows() {
    // const layout = getFromLS("layout");

    this.setState({
      windowHieght: window.innerHeight
    })
  }

  public updateTimer = () => {
    let timecount = 0;
    interval = setInterval(() => {
      timecount++;
      let per = (timecount / this.state.refreshSec) * 100;

      this.setState(
        { timerBar: { "width": per + '%' } }
      )

      if (per > 100) {
        timecount = 0;
        this.getData();
       }
     }, 1000);
  }


  public componentDidMount() {
    this.getData();
    this.resizeWindows();
    this.updateTimer();
    window.addEventListener('resize', this.resizeWindows);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  public getData = () => {

    this.onLayoutChange(this.state.layout);
    // fetch(
    //   'https://api.jsonbin.io/b/' + this.props.user.id, {
    //   method: 'GET',
    //   headers: new Headers({
    //     'secret-key': '$2b$10$wHrEQEUK3m4WJ78pezqkg.QpgBpcLVpri3EO3fA2Zq/D.kSFDE9xy',
    //   })
    // }
    // )
    fetch(config.USERAPI + sessionStorage.getItem("c_user"))
      .then(res => res.json())
      .then(data => {
        var isDraggableTemp
        var isResizeableTemp
        
        if (data["dashboardBlob"][this.state.currentDashboard].permission === 'edit') {
          isDraggableTemp = true;
          isResizeableTemp = true;
        } else {
          isDraggableTemp = false;
          isResizeableTemp = false;
        }
        this.setState({
          updateTime: Date.now(),
          dashboards: data["dashboardBlob"],
          isDraggable: isDraggableTemp,
          isResizable: isResizeableTemp
        });
        dashboardData = JSON.parse(JSON.stringify(this.state.dashboards[this.state.currentDashboard]));

        this.setState({
          loaded: true
        });
        // alert(data.userId + data.uiConfigDTO.theme);
      })
      .catch(console.log);
  }

  public writeDB(dbs) {
    // fetch('https://api.jsonbin.io/b/' + this.props.user.id, {
    //   method: 'PUT',
    //   headers: new Headers({
    //     'secret-key': '$2b$10$wHrEQEUK3m4WJ78pezqkg.QpgBpcLVpri3EO3fA2Zq/D.kSFDE9xy',
    //     'Content-Type': 'application/json',
    //     'versioning': 'false'
    //   }),
    //   body: JSON.stringify(dbs)
    // })
    //   .then(res => res.text())
    //   .then(data => {
    //     /* console.log("udpate", data) */

    //     // alert(data.userId + data.uiConfigDTO.theme);
    //   })
    //   .catch(console.log); // or res.json()


    let UserDTOObject: any = {
      UserDTO: {
        userId: sessionStorage.getItem("c_user"),
        dashboardBlob: dbs
      }
    }
    // console.log(UserDTOObject)
    fetch(config.USERAPI, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(UserDTOObject),
    })
      .then(res => res.json())
      .catch(console.log);
  }



  public render() {

    /* const Map = {
      tileConnect: <TileConnect />,
      tileWallboard: <Transcription />,
      tilePhonebook: <Phonebook />,
      tileAgentqueues: <TileAgentqueues />,
      tileWordcloudAnalytics: <TileWordcloudAnalytics />,
    }; */

    const loaded = this.state.loaded;
    const newDB = this.state.newDB;
    const editDB = this.state.editDB;
    const share = this.state.share;
    const copy = this.state.copy;

    const newWidget = this.state.newWidget;
    const rowHeight = (this.state.windowHieght / 3) - 80

    return (
      // <div style={{ width: '90%', margin: 'auto' }}>

      <div className='db-container' >

        {newDB && (<AddDB addDB={this.addDB} colors={this.props.user.colors} />)}
        {share && (<Share dashboardID={this.state.dashboards[this.state.currentDashboard].id} closeFunc={this.closeShare} />)}
        {editDB && (<EditDB editDB={this.editDB} curData={this.state.dashboards[this.state.currentDashboard]} colors={this.props.user.colors} />)}
        {newWidget && (<SelectWidget addWidget={this.addWidget} />)}
        {copy && loaded && (<Copy curData={dashboardData} copyDB={this.copyDB} />)}

        {!loaded && (<InterfaceLoader />)}

        <InterfaceHeader user={this.props.user} pagename="Tailored Dashboards" />

        <DashBoardSelect managSel={this.managSel} dashboards={this.state.dashboards} curDashboard={this.state.currentDashboard}></DashBoardSelect>
        <UpdateTime updateTime={this.state.updateTime}></UpdateTime>

        {this.props.user.permissions.mainmenu.lock && (<LockDashboard lockStatus={this.state.lockStatus} changeLock={this.lockBoard}></LockDashboard>)}

        {this.props.user.permissions.mainmenu.active && (
        <ActionMenu 
          actionMenuSelect={this.actionMenuSelect} 
          userperms={this.props.user.permissions.mainmenu}
          dashboards={this.state.dashboards}
          currentDashboardSelect={this.currentDashboardSelect}
        >
        </ActionMenu>)}
        
        {loaded && (
        <div className="grid-wrapper content-wrapper-td" id="grid-canvas" ref={this.divRef} style={ {backgroundColor: this.state.dashboards[this.state.currentDashboard].backgroundcolor}}>
        <ReactGridLayout
          rowHeight={rowHeight}
          cols={6}
          layout={this.state.dashboards[this.state.currentDashboard].layout}
          onLayoutChange={this.onLayoutChange}
          margin={[20, 20]}
          preventCollision={false}
          compactType = 'horizontal'
          className="grid-tailored"
          isDraggable = {this.state.isDraggable}
          isResizable = {this.state.isResizable}
          key={this.state.updateTime}

        >

        {Object.keys(this.state.dashboards[this.state.currentDashboard].components).map(
            block =>
            <div className={"widget-module " + this.state.dashboards[this.state.currentDashboard].theme} data-time-stamp={this.state.updateTime} key={this.state.dashboards[this.state.currentDashboard].components[block]._uid.toString()} style={ {backgroundColor: this.state.dashboards[this.state.currentDashboard].components[block].backgroundColor }}>
            <EditWidget changeWidget={this.changeWidget} widgetIndex={block} widgetid={this.state.dashboards[this.state.currentDashboard].components[block]._uid.toString()} componentjson={this.state.dashboards[this.state.currentDashboard].components[block]} queues={this.props.user.queues} />
            {Components(this.state.dashboards[this.state.currentDashboard].components[block])}
            </div>

            )
        }



        </ReactGridLayout>




        </div>
          )}

            <div className="timer-bar"><div className="timer-bar-inner" style={this.state.timerBar}></div></div>
            {this.props.user.permissions.navigation.active && this.props.navbardisplay && (<nav>
              <InterfaceSidebar user={this.props.user} />
            </nav>
            )}
      </div>
    );
  }
}
GridPanel.contextType = ContextObject
