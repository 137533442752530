import * as React from 'react';
import { InterfaceHeader } from '../../components';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
} from '@material-ui/core';
import { ListAlt, Phone } from '@material-ui/icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import './Admin.scss'
import '../../../css/global.scss'
import { InterfaceLoader } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { Switch, Link, Route } from "react-router-dom";
import OutcomesPage from "../Outcomes/Outcomes";
import { InterfaceSidebar } from "../../components";
import CampaignsPage from "../Campaigns/Campaigns";
import ComingSoon from "../../components/ComingSoon/ComingSoon"

interface IProps {
  user: any,
  match: any
}

interface IStates {
  loaded: any,
  showOutcomesPage: boolean,
  showCampaignsPage: boolean,
  configSelected: boolean,
  configs: any[],
  settingSelected: any
}

export default class AdminPage extends React.Component<IProps, IStates> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showOutcomesPage: false,
      showCampaignsPage: false,
      configSelected: false,
      configs: [
        {
          index: 0,
          path: '/outcomes',
          icon: <Phone />,
          title: "Call Outcomes",
          component: <ComingSoon />
        },
        {
          index: 1,
          path: '/campaigns',
          icon: <ListAlt/>,
          title: "Campaigns",
          component: <ComingSoon />
        }
      ],      
      // configs: [
      //   {
      //     index: 0,
      //     path: '/outcomes',
      //     icon: <Phone />,
      //     title: "Call Outcomes",
      //     component: <OutcomesPage />
      //   },
      //   {
      //     index: 1,
      //     path: '/campaigns',
      //     icon: <ListAlt/>,
      //     title: "Campaigns",
      //     component: <CampaignsPage />
      //   }
      // ],
      settingSelected: null
    }
  }

  public componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      })
    },2000)
  }

  public handleSettingSelect(index) {
    this.setState({
      settingSelected: index
    })
  }

  public render() {
    const { loaded, configs, settingSelected } = this.state;

    return (
      <>
      {!loaded && (<InterfaceLoader />)}
      <InterfaceHeader user={this.props.user} pagename="Admin"/>
      <div className="content-wrapper-td">
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper className="settings-paper" elevation={3}>
              <List className="settings-list">
                <Typography className="settings-header">
                  <FontAwesomeIcon icon={faCog} size='1x' className="settings-icon" />SETTINGS
                </Typography>
                {configs.map(setting => {
                  return (
                    <Link key={setting.path} to={'/admin' + setting.path}>
                      <ListItem button selected={settingSelected === setting.index} onClick={() => this.handleSettingSelect(setting.index)}>
                        <ListItemAvatar>
                          <Avatar>
                            {setting.icon}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText disableTypography primary={
                          <Typography className="settings-text">{setting.title}
                          </Typography>} />
                      </ListItem >
                    </Link>
                  )
                })}
              </List>
            </Paper>
          </Grid>
          <Grid className="setting-component" item xs={7}>
              <Switch>
                {configs.map(config => {
                  return (
                    <Route key={config.path} path={'/admin' + config.path}>
                      {config.component}
                    </Route>
                  )
                })}
              </Switch>
          </Grid>
        </Grid>
      </div>
      {this.props.user.permissions.navigation.active && (<nav>
          <InterfaceSidebar user={this.props.user} />
        </nav>
        )}
      </>
    );
  }
}