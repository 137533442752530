import React from 'react';
import Timestamp from 'react-timestamp';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper
} from '@material-ui/core';

interface IData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    type: string;
    timestamp: string;
}


function desc<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(
    order: Order,
    orderBy: K,
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface IHeadRow {
    id: keyof IData;
    label: string;
    numeric: boolean;
}

const headRows: IHeadRow[] = [

    { id: 'firstName', numeric: false, label: 'First Name' },
    { id: 'lastName', numeric: false, label: 'Last Name' },
    { id: 'phoneNumber', numeric: false, label: 'Number' },
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'timestamp', numeric: false, label: 'timestamp' }
];

interface IEnhancedTableProps {
    // eslint-disable-next-line
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IData) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: IEnhancedTableProps) {
    // eslint-disable-next-line
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align='left'
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 550,
        },
        tableWrapper: {
            overflowX: 'auto',
            padding: theme.spacing(1)
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        }
    }),
);

interface IHistoryTableProps {
    dataTable: any;
}

export default function CallHistory(props: IHistoryTableProps) {

    const classes = useStyles();
    const [rows, setRows] = React.useState<IData[]>(props.dataTable);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof IData>('timestamp');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {

        setRows(props.dataTable)
    }, [props.dataTable]);


    function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof IData) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <colgroup>
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>

                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    return (
                                        <TableRow hover
                                            tabIndex={-1}
                                            key={index}
                                            classes={{root:"tbl-row"}}
                                        >
                                            <TableCell align='left' classes={{root:"tbl-cell-first"}}>{row.firstName}</TableCell>
                                            <TableCell align='left' classes={{root:"tbl-cell"}}>{row.lastName}</TableCell>
                                            <TableCell align='left' classes={{root:"tbl-cell"}}>{row.phoneNumber}</TableCell>
                                            <TableCell align='left' classes={{root:"tbl-cell"}}>{row.type}</TableCell>
                                            <TableCell align='left' classes={{root:"tbl-cell"}}><Timestamp relative date={row.timestamp} autoUpdate /></TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

        </div>
    );
}
