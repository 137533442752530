import React from "react";
import Count from "./Modules/count";
import PieGraph from "./Modules/pieGraph";
import LineGraph from "./Modules/lineGraph";
import BarGraph from "./Modules/barGraph";
import Dial from "./Modules/dial";
import TimeFmt from './Modules/timeFmt';

const Components = {
  count: Count,
  pieGraph: PieGraph,
  linegraph: LineGraph,
  barGraph: BarGraph,
  dial: Dial,
  timeFmt: TimeFmt
};

export default block => {
  if (typeof Components[block.visualisation] !== "undefined") {
    return React.createElement(Components[block.visualisation], {
      key: block._uid,
      block: block
    });
  }
  return React.createElement(
    () => <div>The component {block.visualisation} has not been created yet.</div>,
    { key: block._uid }
  );
};
