import React from 'react';
import './Dialer.scss';
import $ from 'jquery';
import { config } from "../../core/config/config";
import { 
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  TextField,
  InputLabel
} from "@material-ui/core";
import Select from 'react-select';

var isLoggedIn: boolean = false;
var loadingDisplay: string = "block";

interface IProps { phoneNumber?: string }
interface IStates {
    phone_number: any;
    show_outcomes_list: boolean;
    selected_outcome: number;
    selected_campaign: string;
    outcomes: any[];
    outcome_expiry: number;
    routing_profile: string;
    outcomes_loading: boolean;
    campaign_outcomes: any[];
}

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? "0 0 0 0.1rem #3f51b5" : 0,
        borderColor: state.isFocused ? "#3f51b5" : "#c4c4c4",
        "&:hover": {
          borderColor: state.isFocused ? "#3f51b5" : "#c4c4c4"
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        backgroundColor: '#FFF',
        color: '#000',
        opacity: 1,
    }),
    menuList: base => ({
        ...base,
        padding: 0,
        backgroundColor: '#FFF',
        height: 150,
    }),
};

const OUTCOME_SELECT_TIMEOUT = 45; // 45 seconds

const CAMPAIGN_TYPES = ["Inbound Call","Outbound Call","Chat","Email"];

export default class InterfaceDialer extends React.Component<IProps, IStates> {

    private connect: any = (window as any).getConnect();
    private ccpURL: string = config.CONNECTINSTANCE + "connect/ccp-v2";
    private ccpLoginURL: string = config.CONNECTINSTANCE + "connect/login/";
    private containerDiv: any;
    private outcome_timer: number;

    constructor(props: any) {
        super(props);

        this.containerDiv = React.createRef();

        this.outcome_timer = 0;

        this.state = {
            phone_number: this.props.phoneNumber,
            show_outcomes_list: false,
            selected_outcome: 100, // set as default for now,
            selected_campaign: "",
            outcomes: [],
            outcome_expiry: OUTCOME_SELECT_TIMEOUT,
            routing_profile: "",
            outcomes_loading: false,
            campaign_outcomes: []
        }
    }

    public initializeCCP() {
        try {

            this.connect.core.initCCP(this.containerDiv.current, {
                ccpUrl: this.ccpURL,
                loginPopup: false,
                loginUrl: this.ccpLoginURL,
                softphone: {
                    allowFramedSoftphone: true
                }
            });

            // tslint:disable-next-line: deprecation
            $(document).ready(() => {
                setTimeout(() => {
                    this.checkConnectAuthorized();
                }, 100);
            });

            this.connect.agent(this.subscribeToAgentEvents.bind(this));
            this.connect.contact((contact) => this.subscribeToContactEvents(contact));
            loadingDisplay = "none";

        } catch (error) {
            console.log(error);
        }

    }

    private checkConnectAuthorized() {

        this.connect.agent((agent: any) => {
            isLoggedIn = true;
        });

        if (!isLoggedIn) {
            const win: any = window.open(
                this.ccpURL,
                "popUpWindow",
                "height=472.867,width=355,top=100,resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=no"
            );
            setInterval(() => {
                if (isLoggedIn) {
                    win.close();
                }
            }, 100);
        }

    }

    componentDidMount() {
        this.initializeCCP();
    }

    componentWillUnmount() {
        this.connect.core.terminate();
    }

    public render() {
        return (
            <div>
                {/* {this.renderOutcomesList()} */}
                <div
                    className="containerDiv"
                    ref={this.containerDiv}
                    id="containerDiv">
                </div>

                <div style={{ display: loadingDisplay }}>
                    <p>Attempting to initialize Amazon Connect, please wait...</p>
                </div>
            </div>

        );
    }

    private subscribeToAgentEvents(agent) {

        // temp store username to session storage
        sessionStorage.setItem("c_user", agent.getConfiguration().username);

        try {

            agent.onStateChange(function (agentStateChange) {

                // fetch(config.AGENTAPI + agent.getConfiguration().username + "/" +
                //     agent.getStatus().name, {
                //     method: "PUT"
                // })
                //     .then(res => res.json())
                //     .catch(console.log);

                const agentSnapShotContacts = agent.toSnapshot()["agentData"]["snapshot"]["contacts"][0];

                let CallHistoryDTOObject: any = {
                    CallHistoryDTO: {
                        connectUserId: "",
                        callHistoryDetails: []
                    }
                }
                if (agentSnapShotContacts) {
                    console.log(agentSnapShotContacts["connections"][0]);
                    if (agentSnapShotContacts["connections"][0]["state"]["type"] === "connected") {

                        CallHistoryDTOObject.CallHistoryDTO.connectUserId = agent.getConfiguration().username
                        CallHistoryDTOObject.CallHistoryDTO.callHistoryDetails.push(agentSnapShotContacts["connections"][1]);
                        console.log("CallHistoryDTO", CallHistoryDTOObject);
                        fetch(config.CALLHISTORYAPI, {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(CallHistoryDTOObject),
                        })
                            .then(res => res.json())
                            .catch(console.log);

                    }
                }
            });

            if (window.location.pathname.substring(9) !== "") {
                this.connect = (window as any).getConnect();
                agent.connect(this.connect.Endpoint.byPhoneNumber(window.location.pathname.substring(9)), {
                    queueARN: "arn:aws:connect:ap-southeast-2:632000501203:instance/0b508490-677b-4c18-8186-12315c4097b4/queue/3f752746-2a6a-4c67-890f-2329e9e53683",
                    success: function () { console.log("call success") },
                    failure: function () { console.log("call failed") }
                });
            }

            this.setState({
                routing_profile: agent.getConfiguration().routingProfile.routingProfileId.split('/')[3]
            })
        } catch (error) {
            console.log(error);
        }

    }

    private subscribeToContactEvents(contact) {
        // let { show_outcomes_list, outcome_expiry } = this.state;

        contact.onConnecting(() => {
            this.setState({
                outcome_expiry: OUTCOME_SELECT_TIMEOUT
            })
            sessionStorage.setItem("onConnecting", "true");
            sessionStorage.setItem("phoneNum", contact.getActiveInitialConnection().getEndpoint().phoneNumber);
        })

        contact.onEnded((contact) => {
            if(!contact.hasOwnProperty('contactData')) { // if contact has been cleared, don't show outcomes list
                this.setState({
                    show_outcomes_list: true
                })
                sessionStorage.setItem("onConnecting", "false");
                sessionStorage.removeItem("phoneNum");
                /*this.outcome_timer = window.setInterval(() => {
                    if(show_outcomes_list && outcome_expiry > 0) {
                        this.setState({
                            outcome_expiry: outcome_expiry - 1
                        })
                    } else {
                        clearInterval(this.outcome_timer);
                        this.setState({
                            show_outcomes_list: false
                        })
                    }
                },1000);*/
            }
        });
    }
    
    private handleSelectOutcome() {
        this.setState({
            show_outcomes_list: false
        })
    }

    private handleSetOutcome(event) {
        this.setState({
            selected_outcome: event.value
        })
    }

    private handleSetCampaignType(event) {
        this.setState({
            selected_campaign: event.value,
            outcomes_loading: true
        })
        fetch(config.CAMPAIGNSAPI + '/campaigns/?type=' + event.value + '&routingProfileId=' + this.state.routing_profile)
        .then(res => res.json())
        .then((result) => {
            this.setState({ 
                campaign_outcomes: result.data.hasOwnProperty('callOutcomesCode') ? result.data.callOutcomesCode:[],
            },() => {
                fetch(config.OUTCOMESAPI + '/getoutcomes')
                .then(res => res.json())
                .then((result) => {
                    this.setState({ 
                        outcomes: result.data.filter(o => this.state.campaign_outcomes.indexOf(o.code) >= 0),
                        outcomes_loading: false
                    })
                })
            })
        })
        .catch(e => {
            console.log("Error getting outcomes:",e);
        })
    }

    private renderOutcomesList() {
        let { show_outcomes_list, outcomes, /*,outcome_expiry*/ outcomes_loading } = this.state;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"
                open={show_outcomes_list}
                >
                <DialogTitle id="confirmation-dialog-title">Select an outcome for this call: {/*({outcome_expiry}s)*/}</DialogTitle>
                <DialogContent dividers>
                    <FormControl>
                        <InputLabel htmlFor="campaign-type-input">Select campaign type</InputLabel>
                        <Select
                            isSearchable={true}
                            id="campaign-type-input"
                            name="campaign-type"
                            options={CAMPAIGN_TYPES.map(c => {
                                return {
                                    value: c,
                                    label: c
                                }
                            })}
                            styles={customStyles}
                            onChange={(e) => this.handleSetCampaignType(e)}
                            className="campaign-select"
                            placeholder="Select a campaign type"
                            />
                        <Select
                            isSearchable={true}
                            id="outcome-input"
                            name="outcomes"
                            options={outcomes.map(o => {
                                return {
                                    value: o.code,
                                    label: o.name
                                }
                            })}
                            styles={customStyles}
                            onChange={(e) => this.handleSetOutcome(e)}
                            placeholder="Select an outcome"
                            isLoading={outcomes_loading}
                            disabled={outcomes_loading}
                            />
                        <TextField
                            className="call-notes"
                            multiline
                            rows={5}
                            placeholder="Enter call notes here..."
                            //value={value}
                            //onChange={handleChange}
                            variant="outlined"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSelectOutcome.bind(this)} color="primary">
                    SAVE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
