import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GithubPicker } from 'react-color';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Typography from '@material-ui/core/Typography';
import '../../../../css/brand/test/interface/addDB.scss'

export default function AddDB (props:any) {
  const {editDB} = props
  const {curData} = props
  const {colors} = props


  const [title, setTitle] = React.useState(curData.name);
  const [theme, setTheme] = React.useState(curData.theme);
  const [backgroundColor, setbackgroundColor] = React.useState(curData.backgroundcolor);



  const titleChange = (event) => {
      setTitle(event.target.value as string);
  };







  const handleChangeColor = (color, event) => {
      /* values["backgroundcolor"] = color.hex */
      setbackgroundColor(color.hex);
  };

  const submitDB = () => {
    curData.name = title

    curData.theme = theme
    curData.backgroundcolor = backgroundColor

    var tempData = { "status": true, data: {} }
    tempData.data = curData

    editDB(tempData);
  };

  const changeTheme = (event: React.ChangeEvent<HTMLInputElement>) => {

     setTheme((event.target as HTMLInputElement).value);
   };

  const cancelDB = () => {
    editDB(curData);
  };





  return (
    <div className="pop-addDB">

      <form className="form-wrapper" noValidate autoComplete="off">
        <h2>Edit Dashboard</h2>
        <div className="add-db-item">
      <TextField id="name" label="Dashboard Name" onChange={titleChange} value={title} />
      </div>
       <Typography className="edit-widget-label">Background Color</Typography>
      <div className="add-db-item">
      <GithubPicker
      colors={colors}
      color={backgroundColor} onChangeComplete={ handleChangeColor }
      width="100%"
        triangle="hide"
      />
      </div>
      <div className="add-db-item">
      <Typography className="edit-widget-label">Theme</Typography>
      <FormControl component="fieldset">

         <RadioGroup aria-label="gender" name="theme" value={theme} onChange={changeTheme} row={true}>
           <FormControlLabel value="light" control={<Radio />} className="radio-item" label="Light" />
           <FormControlLabel value="dark" control={<Radio />} className="radio-item" label="Dark" />
           </RadioGroup>
       </FormControl>
      </div>

        <div className="edit-widget-button-array"><Button onClick={cancelDB}>Cancel</Button><Button onClick={submitDB}>Save</Button></div>
      </form>
    </div>
  );
}
