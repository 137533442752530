import React from 'react';
import DataBearer from './data';
import {Line} from 'react-chartjs-2';
import './lineGraph.scss'
import '../../../../css/brand/test/modules/linegraph.scss'

interface IProps {
    block: any
 }

 interface IStates {
   loaded: boolean
   graphValues: any
 }

 export default class Count extends React.Component<IProps, IStates> {

   constructor(props) {
     super(props);

     this.state = {
       loaded: false,
       graphValues: {}
     };
   }


   public componentDidMount() {

   }

   public dataReturned = data => {
     const loaded = this.state.loaded;


     const tempdata = {
       labels: data.dValue.dates,
       datasets: [
         {
           label: 'Value',
           fill: true,
           lineTension: 0.1,
           backgroundColor: this.props.block.colors[3],
           borderColor: this.props.block.colors[0],
           borderDash: [],
           borderDashOffset: 0.0,
           pointBorderColor: this.props.block.colors[5],
           pointBackgroundColor: '#fff',
           pointBorderWidth: 1,
           pointHoverRadius: 5,
           pointHoverBackgroundColor: this.props.block.colors[0],
           pointHoverBorderColor: this.props.block.colors[5],
           pointHoverBorderWidth: 5,
           pointRadius: 1,
           pointHitRadius: 10,
           data: data.dValue.values
         }
       ]
     };

     this.setState({
        loaded: !loaded,
        graphValues: tempdata
     });
   }




public render() {
  const loaded = this.state.loaded;
  

    const options = {
      legend: { display: false },
      maintainAspectRatio: false,
      scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Time'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Value'
						}
					}]
				}
    }
  return (
    <div
    className="line-item"
    key= {this.props.block._uid}
  >
  <div>
  <p className="count-queue">{this.props.block.queueName}</p>
  {!loaded && (
    <DataBearer queueid={this.props.block.queueid} raw={this.props.block} dataModule={this.props.block.dataModule} element={this.props.block.element} processor="makeGraph" postProcessor={this.dataReturned}/>
  )}


        <div className="line-internal">
              {loaded && ( <Line data={this.state.graphValues} options={options} /> )}
       </div>
     </div>



    <h2>{this.props.block.name} </h2>
  </div>
  );
}
}
