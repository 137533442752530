import * as React from 'react';

import './Login.scss';

import cwlogo from "../../../css/images/Cloudwave-animated.gif";

interface IProps { }

interface IStates {
}

export default class LoginPage extends React.Component<IProps, IStates> {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  public render() {


    return (
      <div className='wb-container'>


        <div className="content-wrapper">
         <div className="loader-image">
           <img src={cwlogo} alt="client logo" />
           <div className="laoder-text">Please login to <br />Amazon Connect</div>
         </div>
       </div>

      </div>
    );
  }
}
