import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import '../../../../css/brand/test/modules/searchTextField.scss'
import widgets from '../../../core/config/widgets.json';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

interface IProps {
    addWidget: any
 }

 interface IStates {
    displayedWidgets: any
 }


 export default class SelectWidget extends React.Component<IProps, IStates> {

   constructor(props) {
     super(props);
     this.state = {
      displayedWidgets: converToArray(widgets)
     };
   }


   public componentDidMount() {

   }

  public serachFilter = (event) => {

       let searcjQery = event.target.value.toLowerCase(),
          displayedWidgets = converToArray(widgets).filter((el) => {
             let searchValue = el["name"] as string;
             let sv = searchValue.toLowerCase();
            return sv.indexOf(searcjQery) !== -1;
          })

      this.setState({
        displayedWidgets: displayedWidgets
      })

  };

  public selectWidget = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.props.addWidget(event.currentTarget.id);
  }








public render() {
  return (
    <div className="pop-select-widget" >
      <div className="select-search">
        <TextField

         className="input-search-box"
       id="input-with-icon-textfield"
       onChange={this.serachFilter}
       InputProps={{
         startAdornment: (
           <InputAdornment position="start">
             <SearchIcon />
           </InputAdornment>
         ),
       }}
     />
     </div>
          <ul className="pop-select-list">
          {Object.keys(this.state.displayedWidgets).map(
            widgetId =>
            <Tooltip title={this.state.displayedWidgets[widgetId].Description} placement="left">
                <Button className="pop-select-item" id={this.state.displayedWidgets[widgetId].id} key={this.state.displayedWidgets[widgetId].id} onClick={this.selectWidget}>
                {this.state.displayedWidgets[widgetId].name}
              </Button>
              </Tooltip>
            )
          }
          </ul>
    </div>
  );
}
}


function converToArray(object) {
    let finalArray = []
    const objectArray = Object.entries(object);
    objectArray.forEach(([key, value]) => {
        finalArray[key] = value;
    });

    return finalArray;
}
