import React  from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';

import './GridPanelPage.scss';
import '../../../css/global.scss'

import { InterfaceUtility } from '../../components';
import { InterfaceHeader } from '../../components';
import { InterfaceSidebar } from '../../components';
import { InterfaceLoader } from '../../components';

import Phonebook from '../../pages/Phonebook';
import TailoredDashBoardPage from "../TailoredDashboards/TailoredDashboardsPage";
import CallHistoryPage from '../CallHistory';
import { config } from "../../core/config/config";
import Cosmos from "../GridPanel/Tiles/TileCosmos/TileCosmos";

const ReactGridLayout = WidthProvider(RGL);
const originalLayout = getFromLS('layout') || [];


interface IProps {
  user: any
 }

interface IUser {
  id: number;
  name: string;
  address: IAddress;
}
interface IAddress {
  street: string;
  suite: string;
  city: string;
  zipcode: string;
}
interface IProfile {
  userId: number;
  theme: string;
  tiles: ITiles[];
}
interface ITiles {
  tilename: string;
  isRender: boolean;
  x: number;
  y: number;
  w: number;
  h: number;
}



export default class GridPanel extends React.Component<IProps> {
  private layout: any;
  private columns: number = 0;
  private rowHeight: number = 0;
  private width: number = 0;
  private rows: number = 0;
  private reloadIframe: boolean = true;

  divRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    const LocalLayout = getFromLS('layout') || [];

    this.state = {
      users: Array<IUser>(),
      error: false,
      profile: { userId: -1, theme: 'empty', tiles: Array<ITiles>() },
      layout: JSON.parse(JSON.stringify(LocalLayout)),
      reloadIframe: true,
      phoneNum: "",
      onConnecting: false
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.resetLayout = this.resetLayout.bind(this);
    
  }

  public state = {
    users: Array<IUser>(),
    error: false,
    profile: { userId: -1, theme: 'empty', tiles: Array<ITiles>() },
    layout: JSON.parse(JSON.stringify(originalLayout)),
    reloadIframe: true,
    onConnecting: false,
    phoneNum: ""
  };

  public resetLayout() {
    this.setState({ layout: {} });
  }

  public onLayoutChange(layout) {

    /*eslint no-console: 0*/
    layout.forEach((el, index) => {
      if (this.divRef.current) {
        const wrapperWidth = this.divRef.current.clientWidth;
        const elementWdith = wrapperWidth*(el.w/4);
        if (elementWdith < 1000) {
          layout[index].style = "medium";
        } else {
          layout[index].style = "large";
        }
      }
    });

    saveToLS('layout', layout);
    this.setState({ layout });
    
  }

  static get defaultProps() {
    return {
      className: 'layout',
      cols: 48,
      rowHeight: 30,
      // tslint:disable-next-line: no-empty
      onLayoutChange: () => { },
    };
  }

  public componentDidMount() {

    this.onLayoutChange(this.state.layout);

    // fetch(
    //   'https://k35zdsnaok.execute-api.ap-southeast-2.amazonaws.com/dev/user/1'
    // )
    //   .then(res => res.json())
    //   .then(data => {
    //     this.setState({
    //       users: data,
    //     });
        
    //   })
    //   .catch(console.log);

    setInterval(() => {
      if(this.state.onConnecting !==  (sessionStorage.getItem("onConnecting") === "true") || this.state.phoneNum !== sessionStorage.getItem("phoneNum")) {
        this.setState({
          reloadIframe: !this.state.reloadIframe,
          phoneNum: sessionStorage.getItem("phoneNum"),
          onConnecting: sessionStorage.getItem("onConnecting") === "true",
        })
        setTimeout(() => {
          this.setState({
            reloadIframe: !this.state.reloadIframe
          });
        },1000);
      } 
    },1000);
   
  }




  public render() {

    const localStyles = this.state.layout;
    if (localStyles.length === 0 ) {
      localStyles.push({style:'medium'});
      localStyles.push({style:'medium'});
      localStyles.push({style:'medium'});
      localStyles.push({style:'medium'});
    }

    const { reloadIframe, phoneNum } = this.state;

    return (
     
      <div className='db-container' >
        <InterfaceHeader user={this.props.user} pagename="Dashboard"/>
        <div className="grid-wrapper content-wrapper-td" id="grid-canvas" ref={this.divRef}>
        <ReactGridLayout
          rowHeight={300}
          cols={4}
          layout={this.state.layout}
          onLayoutChange={this.onLayoutChange}
        >
          {/* <div
            className={localStyles[0].style}
            key='tileTailoredDashboard'
            data-grid={{ x: 0, y:0, w:2, h: 2 }}
          >
            <TailoredDashBoardPage user={this.props.user} navbardisplay={false}/>
          </div> */}
          
          {/* <div
            className={localStyles[2].style}
            key='tilePhonebook'
            data-grid={{ x: 0, y: 2, w: 2, h: 2 }}
          >
            <Phonebook stylesetting="content-wrapper" user={this.props.user} navbardisplay={false}/>
          </div>
          <div
            // className={localStyles[3].style}
            key='tileCallHistory'
            data-grid={{ x: 3, y: 3, w: 2, h: 2 }}
          >
            <CallHistoryPage stylesetting="content-wrapper" user={this.props.user} navbardisplay={false}/>
          </div> */}
          {/*<div
            className={localStyles[3].style}
            key='tileSalesForce'
            data-grid={{ x: 3, y: 4, w: 2, h: 2 }}
          >
            <div className="history-wrapper">
                {!reloadIframe && (<InterfaceLoader />)}
                <div className='component-title topoffset'>Salesforce</div>
                  <InterfaceHeader user={this.props.user} pagename="Salesforce"/>
                {reloadIframe && 
                <div className="content-wrapper">
                  {<iframe title="Salesforce" className="sf_iframe" src={config.SFAPEXURL + "?phoneNum=" + phoneNum} />}
                </div>}
            </div>
          </div>*/}
          <div
            className={localStyles[0].style}
            key='tileCosmos'
            data-grid={{ x: 0, y: 0, w: 2, h: 2 }}
          >
            <div className='component-title topoffset'>COSMOS</div>
              <InterfaceHeader user={this.props.user} pagename="Cosmos"/>
            <Cosmos phoneNum={phoneNum} />
          </div>
        </ReactGridLayout>
        </div>
        {/* <InterfaceUtility /> */}
        {this.props.user.permissions.navigation.active && (<nav>
          <InterfaceSidebar user={this.props.user} />
        </nav>
        )}
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (localStorage) {
    try {    
      ls = JSON.parse(localStorage.getItem('rgl-7')!) || {};
     
    } catch (e) {
      /*Ignore*/
    }
  }
  
  return ls[key];
}

function saveToLS(key, value) {
  
  if (localStorage) {
    localStorage.setItem(
      'rgl-7',
      JSON.stringify({
        [key]: value,
      })
    );
  }
  
}
