import React from 'react';
//import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneSquare
} from '@fortawesome/free-solid-svg-icons';

import './tools.scss';
import TileConnect from '../../pages/GridPanel/Tiles/TileConnect/TileConnect';

interface IProps { }
interface IStates {
  opened: boolean
}

export default class InterfaceToolBar extends React.Component<IProps, IStates> {
  constructor(props) {
		super(props);
		this.state = {
      opened: false
		};
    this.handleClick = this.handleClick.bind(this);
	}

  handleClick() {

    const { opened } = this.state;
		this.setState({
			opened: !opened,
		});
  }

  public render() {
    const { opened } = this.state;


    return (
      <div className="tool-bar">
          <div className="tool tool1" onClick={this.handleClick}>
          <FontAwesomeIcon icon={faPhoneSquare} size='2x' />
          </div>
          {opened && (
          <div className="connector">
          <TileConnect />
          </div>
            )}
      </div>

    );
  }
}
