import React from 'react';
import GridLayout from 'react-grid-layout';

// import '../../../../../node_modules/react-grid-layout/css/style.scss'; //'../../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../../../node_modules/react-resizeable/css/styles.css';

import './Grid.scss';
import InterfaceDialer from '../Dialer/Dialer';

export default class Grid extends React.Component {
  private layout: any;
  private columns: number = 0;
  private rowHeight: number = 0;
  private width: number = 0;
  private rows: number = 0;

  constructor(props: any) {
    super(props);

    this.layout = [
      { i: 'a', x: 0, y: 0, w: 1, h: 2 },
      { i: 'b', x: 1, y: 0, w: 3, h: 2 },
      { i: 'c', x: 2, y: 0, w: 1, h: 2 },
      { i: 'd', x: 3, y: 0, w: 3, h: 4 },
      { i: 'e', x: 4, y: 0, w: 1, h: 2 },
    ];

    this.columns = 4;
    this.rowHeight = window.innerHeight * 0.2275;
    this.width = 320 * 4;
    this.rows = 2;
  }

  public render() {
    return (
      <GridLayout
        className='layout'
        layout={this.layout}
        margin={[25, 25]}
        autoSize={true}
        cols={this.columns}
        rows={this.rows}
        rowHeight={this.rowHeight}
        width={this.width}
      >
        <div key='a'>
          <InterfaceDialer />
        </div>
      </GridLayout>
    );
  }
}
