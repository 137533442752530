import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GithubPicker } from 'react-color';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import previewlight from "../../../../css/images/preview_dark.png";
import previewdark from "../../../../css/images/preview_light.png";
import '../../../../css/brand/test/interface/addDB.scss'

export default function AddDB (props:any) {
  const [previewBG, setpreviewBG] = React.useState("#ffffff");
  const [theme, setTheme] = React.useState('light');
  const [name, setName] = React.useState('');

  const [background, setBackground] = React.useState('#ffffff');

  const {addDB} = props
  const {colors} = props
   let values = {
     "status": false,
     "name" : "",
     "description":"",
     "backgroundcolor":"",
     "theme" : "light"

   };

  const valueChange = (event) => {

      setName(event.target.value)


  };



  const handleChangeColor = (color, event) => {
    console.log(color.hex)
      setBackground(color.hex)
      setpreviewBG(color.hex)
  };

  const changeTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log((event.target as HTMLInputElement).value);
     setTheme((event.target as HTMLInputElement).value);
   };


  const submitDB = () => {

    values["status"] = true;
    values["theme"] = theme;
    values['name'] = name;

    values['backgroundcolor'] = background;
    console.log(values);

    addDB(values);
  };

  const cancelDB = () => {
    addDB(values);
  };

  let previewimage = "";

  if (theme === "dark") {
       previewimage = previewdark;
  } else {
    previewimage = previewlight ;
  }

  return (
    <div className="pop-addDB">

      <form className="form-wrapper" noValidate autoComplete="off">
        <h2>Create Dashboard</h2>
        <div className="add-db-item">
      <TextField id="name" label="Dashboard Name" onChange={valueChange} placeholder="Dashboard Name" />
      </div>

      <div className="add-db-item">
      <Typography className="edit-widget-label">Theme</Typography>
        <GithubPicker
          color="#FFFFFF"
          colors={colors}
          onChangeComplete={ handleChangeColor }
          width="100%"
            triangle="hide"
            />
      </div>
      <div className="add-db-item">
      <Typography className="edit-widget-label">Theme</Typography>
      <FormControl component="fieldset">
         <RadioGroup aria-label="gender" name="theme" value={theme} onChange={changeTheme} row={true}>
           <FormControlLabel value="light" control={<Radio />} className="radio-item" label="Light" />
           <FormControlLabel value="dark" control={<Radio />} className="radio-item" label="Dark" />
           </RadioGroup>
       </FormControl>
      </div>

            <div className="add-db-item">

          <div className="add-db-preview" >
          <div className="preview-text">Preview</div>
            <img src={previewimage} alt='cloudwave logo' className="preview-image" style={ {backgroundColor: previewBG }} />
          </div>
      </div>

        <div className="edit-widget-button-array"><Button onClick={cancelDB}>Cancel</Button><Button onClick={submitDB}>Create</Button></div>
      </form>
    </div>
  );
}
