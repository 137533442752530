import React from 'react';
import { Dialer } from '../../../../components';

import '../../GridPanelPage.scss';
import '../../../Connect/ConnectPage.scss'

export default class TileConnect extends React.Component {
  public render() {
    return (
      <div className='TileBorder'>
        <div>
          <Dialer />
        </div>
      </div>
    );
  }
}
