import React from 'react';
// import logo from "../../../css/images/stockland.png";
import cwlogo from "../../../css/images/CloudWave-Logo-01.png";




//import { Link } from 'react-router-dom';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {
//  faQrcode,
  // faUserCog,
//  faPhone,
//  faChalkboardTeacher,
  // faAddressCard,
//  faPhoneSquare,
//} from '@fortawesome/free-solid-svg-icons';



import './Header.scss';


export default function InterfaceHeader (props:any) {

  return (
            <div className='header'>
                  <img src={cwlogo} alt='cloudwave logo' className="header-logo" />
          </div>
    );
}
