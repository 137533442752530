import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../../../../css/brand/test/interface/dashBoardSelect.scss'


export default function DashBoardSelect (props:any) {
	const {managSel, dashboards, curDashboard} = props
	const [queue, setQueue] = React.useState(''); // eslint-disable-line

	const handleChange = (event) => {
			console.log("change event", event.target.value);
			managSel(event.target.value);
	}

	const selectOption= dashboards.map( (el, i) => ( <MenuItem key={i} value={i}>{el["name"]}</MenuItem>))

	return (
		<div className="change-dashboard">
 					<Select
	           id="dashboard-select"
	           value={curDashboard}
	           onChange={handleChange}
						 variant="standard"
						 IconComponent={ExpandMoreIcon}
	         >
	         	{selectOption}
	         </Select>
 		</div>


	);

}
