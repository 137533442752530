import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    img: {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0        
    }
  });

export default function ComingSoon (props:any) {
    const classes = useStyles();

    return <img className={classes.img} src="/coming-soon.jpg" alt="coming soon" />
}