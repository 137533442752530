import React from 'react';
//import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faColumns,
  faBook,
  faHistory,
  faTh,
  faCog,
} from '@fortawesome/free-solid-svg-icons';

import './Sidebar.scss';

interface IProps {
  user: any

 }
 interface IStates {

 }

export default class InterfaceSidebar extends React.Component <IProps, IStates>{



  public render() {
    
    return (
      <div className='sidebar'>

        {this.props.user.permissions.navigation.dashboard && (<NavLink to='/dashboard' activeClassName="nav-active-1">
          <FontAwesomeIcon icon={faColumns} size='2x' />
        </NavLink>)}
        {/* {this.props.user.permissions.navigation.phonebook && (<NavLink to='/phonebook' activeClassName="nav-active-2">
          <FontAwesomeIcon icon={faBook} size='2x' />
        </NavLink>)} */}
      
        {/* {this.props.user.permissions.navigation.callhistory && (<NavLink to='/callhistory' activeClassName="nav-active-4">
          <FontAwesomeIcon icon={faHistory} size='2x' />         
        </NavLink>)} */}
       
        {this.props.user.permissions.navigation.tailoreddashboards && (<NavLink to='/tailoreddashboard' activeClassName="nav-active-5">
          <FontAwesomeIcon icon={faTh} size='2x' />
        </NavLink>)}

        {/* <NavLink to='/admin' activeClassName="nav-active-6">
          <FontAwesomeIcon icon={faCog} size='2x' />
        </NavLink> */}

      </div>
    );
  }
}
