import React from 'react';
import KebabIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { GithubPicker } from 'react-color';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';


import widgets from '../../../core/config/widgets.json';
import '../../../../css/brand/test/interface/editWidget.scss'


export default function AddDB (props:any) {


  const {widgetIndex} = props
  const {componentjson} = props
  const {changeWidget} = props
  const {queues} = props




  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [wType, setwType] = React.useState(componentjson.visualisation);
  const [backgroundColor, setbackgroundColor] = React.useState(componentjson.backgroundColor);
  const [dateRangeValue, setdateRangeValue] = React.useState(componentjson.dateRange.dateRangeValue);
  const [dateRange, setdateRange] = React.useState(componentjson.dateRange.dateRangeUnit);
  const [queueSelect, setqueueSelect] = React.useState(componentjson.queueid);
  const [warningThreshold, setwarningThreshold] = React.useState(componentjson.threshold.warning.value);
  const [alertThreshold, setalertThreshold] = React.useState(componentjson.threshold.alert.value);
  const [warningThresholdstate, setwarningThresholdstate] = React.useState(componentjson.threshold.warning.enabled);
  const [alertThresholdstate, setalertThresholdstate] = React.useState(componentjson.threshold.alert.enabled);
  const [warningThresholdcalc, setwarningThresholdcalc] = React.useState(componentjson.threshold.warning.calc);
  const [alertThresholdcalc, setalertThresholdcalc] = React.useState(componentjson.threshold.alert.calc);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const updateSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setwType(event.target.value as string);
  };
  const updateSelectRange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setdateRange(event.target.value as string);
  };

  const updateQueue = (event: React.ChangeEvent<{ value: unknown }>) => {
    setqueueSelect(event.target.value as string);
  };

  const handleChangeColor = (color, event) => {
    setbackgroundColor(color.hex);
  };

  const handleDateRangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdateRangeValue(event.target.value);
  };

  const warningThresholdChange  = (event: React.ChangeEvent<HTMLInputElement>) => {
    setwarningThreshold(event.target.value);
  };
  const alertThresholdChange  = (event: React.ChangeEvent<HTMLInputElement>) => {
    setalertThreshold(event.target.value);
  };

  const enableWarning  = (event: React.ChangeEvent<HTMLInputElement>) => {
      setwarningThresholdstate(event.target.checked)
    };

    const enableAlert  = (event: React.ChangeEvent<HTMLInputElement>) => {
        setalertThresholdstate(event.target.checked)
      };

    const changeWarningCalc = (event: React.ChangeEvent<{ value: unknown }>) => {
      setwarningThresholdcalc(event.target.value as string);
    };

    const changeAlertCalc = (event: React.ChangeEvent<{ value: unknown }>) => {
      setalertThresholdcalc(event.target.value as string);
    };

  const handlePress = (e) => {
    e.stopPropagation();
  }


  const submitDB = () => {
    componentjson.queueid = queueSelect

    queues.map(function(item, i){

      if (item.QueueID === queueSelect) {

          componentjson.queueName = item.name
      }
      return null
    })



    componentjson.visualisation = wType
    componentjson.dateRange.dateRangeValue = dateRangeValue
    componentjson.dateRange.dateRangeUnit = dateRange
    componentjson.backgroundColor = backgroundColor
    componentjson.threshold.warning.value = warningThreshold
    componentjson.threshold.alert.value = alertThreshold
    componentjson.threshold.warning.enabled = warningThresholdstate
    componentjson.threshold.alert.enabled = alertThresholdstate
    componentjson.threshold.warning.calc = warningThresholdcalc
    componentjson.threshold.alert.calc = alertThresholdcalc
    console.log("presend", componentjson);
    changeWidget(componentjson)
    setAnchorEl(null)
  };

  const cancelDB = () => {
    setAnchorEl(null);
  };
  const deleteWidget = () => {
    changeWidget({ "delete" : true, "index" : widgetIndex })
    setAnchorEl(null);
  };



  return (
    <div onMouseDown={e => handlePress(e)}>
    <button className="widget-menu-icon" onClick={handleClick}><KebabIcon fontSize="small"  /></button>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className='edit-widget'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >

      <Typography className="test">Edit Widget</Typography><div className="action-buttons"><DeleteIcon onClick={deleteWidget}/></div>
      <div className="edit-widget-item">
      <FormControl>
        <InputLabel id="queue-select-label">Queue</InputLabel>
        <Select
          id="queue-select"
          value={queueSelect}
          onChange={updateQueue}
          >
          {queues.map((queue, index) => (
              <MenuItem key={queues[index].QueueID} value={queues[index].QueueID}>{queues[index].name}</MenuItem>
          ))}
        </Select>
          </FormControl>
      </div>
      <div className="edit-widget-item">
       <FormControl>
       <InputLabel id="visualisation-select-label">Visualisation</InputLabel>
        <Select

          id="visualisation-select"
          value={wType}
          onChange={updateSelect}
          >
            {widgets[componentjson.type].visualisations.map((visname, index) => (
                <MenuItem key={visname} value={visname}>{visname}</MenuItem>
            ))}
        </Select>
        </FormControl>
      </div>
      {componentjson.dateRange.active && (
      <div className="edit-widget-item">

        <Typography className="edit-widget-label">Range</Typography>
        <div className="edit-widget-split-left">
        <TextField id="filled-basic"  onChange={handleDateRangeValue} value={dateRangeValue} />
        </div>
        <div className="edit-widget-split-right">
        <Select
          id="date-range-selector"
          value={dateRange}
          onChange={updateSelectRange}
          >
            <MenuItem key="mins" value="mins">Mins</MenuItem>
            <MenuItem key="days" value="days">Days</MenuItem>

        </ Select>
        </div>
      </div>
       )}
       {componentjson.threshold.active && ( <div className="edit-widget-item inline-selector">
           <Typography className="edit-widget-label">Thresholds</Typography>

            <div className="edit-widget-split-left">
                         <Typography className="edit-widget-label">Warning</Typography>

            </div>
           <div className="edit-widget-split-right">
                       <Switch
                          checked={warningThresholdstate}
                          onChange={enableWarning}
                          name="warningThresholdstate"
                          size="small"

                        />
          </div>


              {!warningThresholdstate && (
              <div>
                <div className="edit-widget-split-left">
                    <Select
                      id="warning-calc-selector"
                      value={warningThresholdcalc}
                      onChange={changeWarningCalc}
                      disabled={true}
                      >
                        <MenuItem key="gte" value="gte">Greater than</MenuItem>
                        <MenuItem key="lte" value="lte">Less than</MenuItem>

                    </ Select>
                  </div>
                   <div className="edit-widget-split-right">
                   <TextField id="the-warning-threshold"  onChange={warningThresholdChange} value={warningThreshold} disabled={true}/>
                  </div>
              </div>
              )}
              {warningThresholdstate && (
              <div>
                <div className="edit-widget-split-left">
                    <Select
                      id="warning-calc-selector"
                      value={warningThresholdcalc}
                      onChange={changeWarningCalc}

                      >
                        <MenuItem key="gte" value="gte">Greater than</MenuItem>
                        <MenuItem key="lte" value="lte">Less than</MenuItem>

                    </ Select>
                  </div>

                   <div className="edit-widget-split-right">
                    <TextField id="the-warning-threshold"  onChange={warningThresholdChange} value={warningThreshold} />
                  </div>
                </div>
              )}



              <div className="edit-widget-split-left">
                <Typography className="edit-widget-label">Alert</Typography>
              </div>
               <div className="edit-widget-split-right">
                <Switch
                   checked={alertThresholdstate}
                   onChange={enableAlert}
                   name="alertThresholdstate"
                   size="small"

                 />
                </div>

             {!alertThresholdstate && (
               <div>
                 <div className="edit-widget-split-left">
                 <Select
                   id="alert-calc-selector"
                   value={alertThresholdcalc}
                   onChange={changeAlertCalc}
                   disabled={true}
                   >
                     <MenuItem key="gte" value="gte">Greater than</MenuItem>
                     <MenuItem key="lte" value="lte">Less than</MenuItem>

                 </ Select>
                 </div>
                 <div className="edit-widget-split-right">
                    <TextField id="the-alert-threshold"  onChange={alertThresholdChange} value={alertThreshold} disabled={true} />
                </div>
              </div>
             )}
             {alertThresholdstate && (
               <div>
                <div className="edit-widget-split-left">
                   <Select
                     id="alert-calc-selector"
                     value={alertThresholdcalc}
                     onChange={changeAlertCalc}
                     >
                       <MenuItem key="gte" value="gte">Greater than</MenuItem>
                       <MenuItem key="lte" value="lte">Less than</MenuItem>

                   </ Select>
                  </div>
                  <div className="edit-widget-split-right">
                    <TextField id="the-alert-threshold"  onChange={alertThresholdChange} value={alertThreshold} />
                  </div>
                </div>
             )}
         </div>
       )}
      <Typography className="edit-widget-label">Background Colour</Typography>
      <div className="edit-widget-item">
        <GithubPicker
          width="100%"
          triangle="hide"
          colors={componentjson.colors}
          color={ backgroundColor }
          onChangeComplete={ handleChangeColor }
          />
      </div>
      <div className="edit-widget-button-array"><Button onClick={cancelDB}>Cancel</Button><Button onClick={submitDB}>OK</Button></div>

      </Popover>
      </div>
  );
}
