import React from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Paper,
	Checkbox,
	IconButton,
	Tooltip
	// FormControlLabel,
	// Switch
} from '@material-ui/core';
import {
	Delete as DeleteIcon
} from '@material-ui/icons';

interface IData {
	fullName: string;
	organization: string;
	phoneNumber: string;
	numberType: string;
	connectStatus: string;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(
	order: Order,
	orderBy: K,
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface IHeadRow {
	id: keyof IData;
	label: string;
	numeric: boolean;
}

const headRows: IHeadRow[] = [

	{ id: 'fullName', numeric: false, label: 'Name' },
	{ id: 'organization', numeric: false, label: 'Organization' },
	{ id: 'phoneNumber', numeric: true, label: 'Number' },
	{ id: 'numberType', numeric: false, label: 'Number Type' },
];

interface IEnhancedTableProps {
	// eslint-disable-next-line
	classes: ReturnType<typeof useStyles>;
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IData) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: IEnhancedTableProps) {
	// eslint-disable-next-line
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
					/>
				</TableCell>
				{headRows.map(row => (
					<TableCell
						key={row.id}
						align='left'
						sortDirection={orderBy === row.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === row.id}
							direction={order}
							onClick={createSortHandler(row.id)}
						>
							{row.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
		},
		highlight:
			theme.palette.type === 'light'
				? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
				: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
		spacer: {
			flex: '1 1 100%',
		},
		actions: {
			color: theme.palette.text.secondary,
		},
		title: {
			flex: '0 0 auto',
		},
	}),
);

interface IEnhancedTableToolbarProps {
	numSelected: number;
	handleDelete: (event: React.MouseEvent) => void;
}

const EnhancedTableToolbar = (props: IEnhancedTableToolbarProps) => {
	const classes = useToolbarStyles();
	const { numSelected } = props;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<div className={classes.title}>
				{numSelected > 0 ? (
					<Typography color="inherit" variant="subtitle1">
						{numSelected} selected
					</Typography>
				) : (
					<Typography variant="h6" id="tableTitle">
						Phonebook
					</Typography>
				)}
			</div>
			<div className={classes.spacer} />
			<div className={classes.actions}>
				{numSelected > 0 && (
					<Tooltip title="Delete">
						<IconButton aria-label="delete" onClick={props.handleDelete}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
			</div>
		</Toolbar>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing(1),
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		table: {
			minWidth: 550,
		},
		tableWrapper: {
			overflowX: 'auto',
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		status: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center'
		},
		Available: {
			width: 20,
			height: 20,
			borderRadius: 10,
			background: 'green'
		},
		offline: {
			width: 20,
			height: 20,
			borderRadius: 10,
			background: 'grey'
		}
	}),
);

interface IPhonebookTableProps {
	dataTable: any;
}

export default function PhonebookTableFull(props: IPhonebookTableProps) {
	const classes = useStyles();

	const [rows, setRows] = React.useState<IData[]>(props.dataTable);
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof IData>('fullName');
	const [selected, setSelected] = React.useState<string[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	React.useEffect(() => {
		setRows(props.dataTable)
	}, [props.dataTable]);

	function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof IData) {
		const isDesc = orderBy === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderBy(property);
	}

	function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.fullName);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	}

	function handleClick(event: React.MouseEvent<unknown>, name: string) {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	}

	function handleChangePage(event: unknown, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	function handleDelete(event: React.MouseEvent) {
		const newRows: IData[] = [];
		rows.forEach(row => {
			const selectedIndex = selected.indexOf(row.fullName);

			if ( selectedIndex === -1 ) {
				newRows.push(row);
			}
		});
		setRows(newRows);
		setSelected([]);
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					handleDelete={handleDelete}
				/>
				<div className={classes.tableWrapper}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size='medium'
					>
						<colgroup>
							<col style={{ width: '50px' }} />
							<col style={{ width: '5%px' }} />
							<col style={{ width: '25%' }} />
							<col style={{ width: '25%' }} />
							<col style={{ width: '25%' }} />
							<col style={{ width: '40%' }} />
						</colgroup>

						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{stableSort(rows, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.fullName);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={event => handleClick(event, row.fullName)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.fullName}
											selected={isItemSelected}
											classes={{root:"tbl-row", hover:"tbl-row-hover", selected: "tbl-row-selected"}}
										>
											<TableCell padding="checkbox" classes={{root:"tbl-cell-cb"}}>
												<Checkbox
													checked={isItemSelected}
													inputProps={{ 'aria-labelledby': labelId }}
													classes={{root:"tbl-checkbox"}}
												/>
											</TableCell>

											<TableCell component="th" id={labelId} scope="row" padding="none" align='left' classes={{root:"tbl-cell"}}>
												{row.fullName}
											</TableCell>
											<TableCell align="left" classes={{root:"tbl-cell"}}>{row.organization}</TableCell>
											<TableCell align="left" classes={{root:"tbl-cell"}}>{row.phoneNumber}</TableCell>
											<TableCell align="left" classes={{root:"tbl-cell"}}>{row.numberType}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						'aria-label': 'previous page',
					}}
					nextIconButtonProps={{
						'aria-label': 'next page',
					}}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			{/*<FormControlLabel*/}
			{/*control={<Switch checked={dense} onChange={handleChangeDense} />}*/}
			{/*label="Dense padding"*/}
			{/*/>*/}
		</div>
	);
}
