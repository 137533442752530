import React from 'react';
import './barGraph.scss'
import '../../../../css/brand/test/modules/count.scss'

const pStyle = {
  width: '80%'
};


export default props => (
  <div
    className="bar-item"

  >
    <div className="bar-back">
      <div className="bar-front" style={pStyle}>80%</div>
    </div>

    <h2>{props.block.name} </h2>
  </div>
);
