const dev = {
    AGENTAPI: "https://p5fodb12ok.execute-api.ap-southeast-2.amazonaws.com/dev/Agent/",    
    WALLBOARDAPI: "https://k4idsw89zc.execute-api.ap-southeast-2.amazonaws.com/dev/wallboardmetrics/",
    QUEUE: "4e344e16-1ab1-48cd-8b5a-2aa9d663f0e1",
    OUTCOMESAPI: "https://6bm32lvjf7.execute-api.ap-southeast-2.amazonaws.com/prod",    
    CAMPAIGNSAPI: "https://j1yu5y6ixe.execute-api.ap-southeast-2.amazonaws.com/prod",
    NEONAGENTAPI: "https://048pyesb9g.execute-api.ap-southeast-2.amazonaws.com/dev/agent",
    SFAPEXURL: "https://cloudwavee--jeromedg--c.visualforce.com/apex/Neon_SalesForce_Contact",

    CONNECTINSTANCE: "https://coa-dev.awsapps.com/",
    ARN:"arn:aws:connect:ap-southeast-2:153796402129:instance",
    CONNECTINSTANCEID:"e71d011d-bdf0-460a-8ba2-8e7974c64851",
    CALLHISTORYAPI: "https://p5262mh5kd.execute-api.ap-southeast-2.amazonaws.com/dev/callhistory/",
    USERAPI: "https://7ohgrzy233.execute-api.ap-southeast-2.amazonaws.com/dev/neonuser/",
    INDEXNAME: 'dev-queues-coa',
    ELASTICENDPOINT: "https://elastic:nEbcT0AxFSPbYcphKWvYaaSB@7473f0699d4e4123b103365143bc519c.ap-southeast-2.aws.found.io:9243",
    COSMOSAPI: {
      TOKEN_URL: "https://optilyzeau.au.auth0.com/oauth/token",
      CLIENT_ID: "epVs5Q6eL8uKF9GixM8FeOWjhdBjokg7",
      SECRET_KEY: process.env.REACT_APP_COSMOS_SECRET,
      API_URL: "https://api.cosmos-cms.com.au/api/models"
    }
   };

const uat = {
    CONNECTINSTANCE: "https://coa-uat.awsapps.com/",
    ARN:"arn:aws:connect:ap-southeast-2:868475677626:instance",
    CONNECTINSTANCEID:"dae25e4c-ac25-4dea-b8a8-2b2832794e3a",
    CALLHISTORYAPI: "https://9uwt9shno7.execute-api.ap-southeast-2.amazonaws.com/dev/callhistory/",
    USERAPI: "https://1pt4o7g8zh.execute-api.ap-southeast-2.amazonaws.com/dev/neonuser/",
    INDEXNAME: 'uat-queues-coa',
    ELASTICENDPOINT: "https://elastic:nEbcT0AxFSPbYcphKWvYaaSB@7473f0699d4e4123b103365143bc519c.ap-southeast-2.aws.found.io:9243",
    COSMOSAPI: {
      TOKEN_URL: "https://optilyzeau.au.auth0.com/oauth/token",
      CLIENT_ID: "epVs5Q6eL8uKF9GixM8FeOWjhdBjokg7",
      SECRET_KEY: "yqp7QdC_Sdw-68xP5emgxdi5ZMZ6hc0p7Oq7p9IrO43DPdAyvvHO0xmhECk4aJSt",
      API_URL: "https://api.cosmos-cms.com.au/api/models"
    }
}

const prod = {
    CONNECTINSTANCE: "https://communityoptions.awsapps.com/",
    ARN:"arn:aws:connect:ap-southeast-2:868475677626:instance",
    CONNECTINSTANCEID:"a568bd12-890c-4dd8-b8e1-59a959f4d72d",
    CALLHISTORYAPI: "https://x8k8ff3212.execute-api.ap-southeast-2.amazonaws.com/dev/callhistory/",
    USERAPI: "https://6vmchyv3a9.execute-api.ap-southeast-2.amazonaws.com/dev/neonuser/",   
    INDEXNAME: 'prod-queues-coa',
    ELASTICENDPOINT: "https://elastic:nEbcT0AxFSPbYcphKWvYaaSB@7473f0699d4e4123b103365143bc519c.ap-southeast-2.aws.found.io:9243"
};

export const config =
  (process.env.REACT_APP_STAGE === "prod" ? prod
    : process.env.REACT_APP_STAGE === "dev" ? dev
      : process.env.REACT_APP_STAGE === "uat" ? uat
        : dev);