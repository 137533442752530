import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GithubPicker } from 'react-color';
import '../../../../css/brand/test/interface/addDB.scss'

export default function AddDB (props:any) {
  const {copyDB} = props
  const {curData} = props

  curData.name = curData.name + " Copy"
  const [title, setTitle] = React.useState(curData.name);
  const [backgroundColor, setbackgroundColor] = React.useState(curData.backgroundcolor);
  const [description, setDescription] = React.useState();


  const titleChange = (event) => {
      setTitle(event.target.value as string);
  };

  const descriptionChange = (event) => {
    setDescription(event.target.value as string);
  };





  const handleChangeColor = (color, event) => {
      /* values["backgroundcolor"] = color.hex */
      setbackgroundColor(color.hex);
  };

  const submitDB = () => {
    curData.id = "d" + Date.now()
    curData.name = title
    curData.description = description
    curData.backgroundcolor = backgroundColor

    var tempData = { "status": true, data: {} }
    tempData.data = curData
    console.log("editdb",tempData);
    copyDB(tempData);
  };

  const cancelDB = () => {
    copyDB(curData);
  };



  return (
    <div className="pop-addDB">

      <form className="form-wrapper" noValidate autoComplete="off">
        <h2>Edit Dashboard</h2>
        <div className="add-db-item">
      <TextField id="name" label="Dashboard Name" onChange={titleChange} value={title} />
      </div>

      <div className="add-db-item">
      <p>Background color:</p> <GithubPicker colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3']} color={backgroundColor} onChangeComplete={ handleChangeColor }/>
      </div>
      <div className="add-db-item">
        <TextField id="description" label="Description" onChange={descriptionChange} value={description}/>
      </div>
        <div className="edit-widget-button-array"><Button onClick={cancelDB}>Cancel</Button><Button onClick={submitDB}>Save</Button></div>
      </form>
    </div>
  );
}
