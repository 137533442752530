import React from 'react';
import DataBearer from './data';
import './timeFmt.scss'
import '../../../../css/brand/test/modules/timeFmt.scss'

interface IProps {
    block: any

 }

 interface IStates {
   loaded: boolean
   countValue: any
 }


export default class Count extends React.Component<IProps, IStates> {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      countValue: 0
    };
  }


  public componentDidMount() {

  }

  public dataReturned = data => {
    const loaded = this.state.loaded;

    this.setState({
      loaded: !loaded,
      countValue: data.dValue
    });

  }



  public render() {
    const loaded = this.state.loaded;
    return (
        <div
        className="grid-item"
        >
          <p className="timefmt-queue">{this.props.block.queueName}</p>
        {!loaded && (
          <DataBearer queueid={this.props.block.queueid} raw={this.props.block} dataModule={this.props.block.dataModule} element={this.props.block.element} processor={this.props.block.processor} postProcessor={this.dataReturned}/>
        )}
        {loaded && (
          <div className="timefmt-number" data-count={this.state.countValue}  data-warning-threshold-calc={this.props.block.threshold.warning.calc} data-warning-threshold-enabled={this.props.block.threshold.warning.enabled} data-warning-threshold={this.props.block.threshold.warning.value} data-alert-threshold-enabled={this.props.block.threshold.alert.enabled} data-alert-threshold-calc={this.props.block.threshold.alert.calc} data-alert-threshold={this.props.block.threshold.alert.value} data-threshold-state={this.props.block.threshold.active}>{this.state.countValue}</div>
        )}
          <h2>{this.props.block.name}</h2>


        </div>
    );
  }
}
