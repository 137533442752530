import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './dial.scss'

const data = {
  labels: [
    'Percent',
    'Percent',

  ],
  datasets: [{
    data: [80,20],
    backgroundColor: [
      '#18B961',
      '#999999'
    ],
    hoverBackgroundColor: [
      '#18B961',
      '#999999'
    ],
    borderColor: [
      '#ffffff',
      '#ffffff'
    ],
  }]
};

const options = {
  circumference: 1 * Math.PI,
  rotation: 1 * Math.PI,
  legend: { display: false },
  maintainAspectRatio: false
}

export default props => (
    <div
    className="dial-item"
    key= {props.block._uid}
  >
  <div>
    <div className="dial-internal">
       <Doughnut
       data={data}
       options={options}
       />
     </div>
     </div>


    <h2>{props.block.name} </h2>
  </div>
);
