import React from 'react';
import { Fab } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBook,
  faHistory,
  faSignal
} from '@fortawesome/free-solid-svg-icons';

import './Utility.scss';
import { Redirect } from 'react-router-dom';

interface IProps { }
interface IStates {
  opened: boolean
  phonebook: boolean;
  callhistory:boolean;
  tboard: boolean;
}

export default class InterfaceUtility extends React.Component<IProps, IStates> {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      phonebook: false,
      callhistory:false,
      tboard:false

    };
    this.handleClick = this.handleClick.bind(this);
    this.handleListItemPhonebookClick=this.handleListItemPhonebookClick.bind(this);
    this.handleListItemCallHistoryClick=this.handleListItemCallHistoryClick.bind(this);
    this.handleListItemPerformanceClick=this.handleListItemPerformanceClick.bind(this);
  }

  handleClick() {

    const { opened } = this.state;
    this.setState({
      opened: !opened
    
    });
  }

  handleListItemPhonebookClick() {
    
    this.setState({
      phonebook: true
    });
  }

  handleListItemCallHistoryClick() {
    
    this.setState({
      callhistory: true
    });
  }

  handleListItemPerformanceClick() {
    
    this.setState({
      tboard: true
    });
  }

  public render() {
    if (this.state.phonebook) {
      return <Redirect push to="/phonebook" />;
    }
    else if (this.state.callhistory){
      return <Redirect push to="/callhistory" />;
    }
    else if(this.state.tboard){
      return <Redirect push to="/tailoreddashboard" />;
    }
    const { opened } = this.state;
    return (
      <div>
        <Fab
          color="primary"
          aria-label="add"
          id="fl-add"
          onClick={this.handleClick}
        >
          +
          </Fab>
        {opened && (
          <List component="ul" aria-label="main mailbox folders" id="menuList">
            <ListItem button className="menuItem active-2" onClick={this.handleListItemPhonebookClick} >
              <ListItemIcon className="menuIcon">
                <FontAwesomeIcon icon={faBook} />
              </ListItemIcon>
              <ListItemText primary="Phonebook" className="menuText" />
            </ListItem>
            <ListItem button className="menuItem active-4" onClick={this.handleListItemCallHistoryClick}>
              <ListItemIcon className="menuIcon">
                <FontAwesomeIcon icon={faHistory} />
              </ListItemIcon>
              <ListItemText primary="Call History" className="menuText" />
            </ListItem>
            <ListItem button className="menuItem active-5" onClick={this.handleListItemPerformanceClick}>
              <ListItemIcon className="menuIcon">
                <FontAwesomeIcon icon={faSignal} />
              </ListItemIcon>
              <ListItemText primary="Performance" className="menuText" />
            </ListItem>
          </List>
        )}
      </div>
    );
  }
}
