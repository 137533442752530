import React from 'react';
import {
  Paper,
  InputBase,
} from '@material-ui/core';
import {
  Search as SearchIcon
} from '@material-ui/icons';



export default function CustomizedInputBase({ onChange }) {


  return (
    <Paper className="searchbar-wrapper" square>
      <SearchIcon />
      <InputBase
        className="searchbar-textfield"
        onChange={onChange}
        placeholder="Search by Name, Type, Group"
        inputProps={{ 'aria-label': 'search' }}
      />
    </Paper>
  );
}
