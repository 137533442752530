import React  from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';

import './TailoredDashboardsPage.scss';
import '../../../css/global.scss'

import InterfaceHeader from '../../components/Header/HeaderViewer';
import Components from "./components";

import { InterfaceLoader } from '../../components';
import UpdateTime from './Interface/updateTime';
import { config } from '../../core/config/config';



const ReactGridLayout = WidthProvider(RGL);

interface IProps {
  match: any
  user: any

 }

interface IProfile {
  userId: number;
  theme: string;
}

interface IStates {
  loaded: boolean,
  users: any,
  error: any,
  profile: any,
  dashboards: any,
  layout: any,
  currentDashboard: any,
  newDB: boolean,
  editDB: boolean,
  newWidget: boolean,
  lockStatus: boolean,
  share: boolean,
  copy:boolean,
  windowHieght: any
  isDraggable: boolean,
  isResizable: boolean,
  updateTime: number,
  timerBar: any
}


var interval;

export default class GridPanel extends React.Component<IProps, IStates> {
  private layout: any;
  private columns: number = 0;
  private rowHeight: number = 0;
  private width: number = 0;
  private rows: number = 0;


  divRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      users: [],
      error: false,
      profile: { userId: -1, theme: 'empty' },
      dashboards: [],
      layout: [],
      currentDashboard: 0,
      loaded: false,
      newDB: false,
      editDB: false,
      newWidget: false,
      share: false,
      copy:false,
      lockStatus: false,
      windowHieght: 1000,
      isDraggable: false,
      isResizable: false,
      updateTime: Date.now(),
      timerBar: {"width" : "0%"}
    };


    this.resizeWindows = this.resizeWindows.bind(this);
    }



    public resizeWindows() {
        // const layout = getFromLS("layout");

        this.setState({
          windowHieght: window.innerHeight
        })
    }

    public updateTimer = () => {
      let timecount = 0;
      interval = setInterval(() => {
        timecount++;
        let per = (timecount/60)*100;

        this.setState(
            { timerBar: {"width": per + '%'} }
        )

        if (timecount === 60) {
        this.getData();
         this.setState(
             { updateTime: Date.now() }
         )
         timecount = 0;
         }
       }, 1000);
    }

    public updateDBlayout(db) {
        this.setState({ layout: this.state.dashboards[db].layout})
    }

    public componentDidMount() {
        this.getData();
        this.resizeWindows();
        this.updateTimer();
        window.addEventListener('resize', this.resizeWindows);
    }


    componentWillUnmount() {
        clearInterval(interval);
    }


  public getData = () => {


    // fetch(
    //   'https://api.jsonbin.io/b/'+this.props.user.id, {
    //     method: 'GET',
    //     headers: new Headers({
    //      'secret-key': '$2b$10$wHrEQEUK3m4WJ78pezqkg.QpgBpcLVpri3EO3fA2Zq/D.kSFDE9xy',
    //      })
    //   }
    // )
    fetch(config.USERAPI + sessionStorage.getItem("c_user"))
      .then(res => res.json())
      .then(data => {

        let newDashboards = [] as any
        data["configBlob"].map((dashboard, index) => {
          if (dashboard.id === this.props.match.params.boardID) {
              newDashboards.push(dashboard);

              this.setState({
                dashboards: newDashboards,
              });
              this.updateDBlayout(this.state.currentDashboard)
              this.setState({
                loaded: true
              });
          }
          return null
        })

        // alert(data.userId + data.uiConfigDTO.theme);
      })
      .catch(console.log);

   }



  public render() {
    const loaded = this.state.loaded;
    const rowHeight = (this.state.windowHieght/3) - 80

    return (

      <div className='db-container boardviewer' >

        {!loaded && (<InterfaceLoader />)}

        <InterfaceHeader user={this.props.user} pagename="Tailored Dashboards"/>


        <UpdateTime updateTime={this.state.updateTime}></UpdateTime>


        {loaded && (
        <div className="grid-wrapper content-wrapper-td" id="grid-canvas" ref={this.divRef} style={ {backgroundColor: this.state.dashboards[this.state.currentDashboard].backgroundcolor}}>
        <ReactGridLayout
          rowHeight={rowHeight}
          cols={6}
          layout={this.state.layout}

          margin={[20, 20]}
          preventCollision={false}
          compactType = 'horizontal'
          className="grid-tailored"
          isDraggable = {this.state.isDraggable}
          isResizable = {this.state.isResizable}
          key={this.state.updateTime}

        >

        {Object.keys(this.state.dashboards[this.state.currentDashboard].components).map(
            block =>
            <div className={"widget-module " + this.state.dashboards[this.state.currentDashboard].theme} data-time-stamp={this.state.updateTime} key={this.state.dashboards[this.state.currentDashboard].components[block]._uid.toString()} style={ {backgroundColor: this.state.dashboards[this.state.currentDashboard].components[block].backgroundColor }}>

            {Components(this.state.dashboards[this.state.currentDashboard].components[block])}
            </div>

            )
        }



        </ReactGridLayout>




        </div>
          )}

            <div className="timer-bar"><div className="timer-bar-inner" style={this.state.timerBar}></div></div>
      </div>

    );
  }
}
