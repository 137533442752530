import React from 'react';
import CallHistory from "./CallHistory"
import { InterfaceHeader } from '../../components';
import { InterfaceLoader } from '../../components';
import { InterfaceSidebar } from '../../components';
import { config } from "../../core/config/config";


import './CallHistory.scss';

interface IProps {
    stylesetting: any;
    user: any;
    navbardisplay: boolean;
}

interface IStates {
    history: any[];
    loaded: boolean;
}
export default class CallHistoryPage extends React.Component<IProps, IStates> {
    constructor(props: any) {
        super(props);

        this.state = {
            history: [],
            loaded: false
        };
    }

    createData(firstName: string, lastName: string, phoneNumber: string, type: string, timestamp: string) {
        return { firstName, lastName, phoneNumber, type, timestamp };
    }

    public componentDidMount() {
        if (sessionStorage.getItem("c_user")) {
            this.setState({ loaded: true });
            // const loaded = this.state.loaded;
            // fetch('https://kca600k4f8.execute-api.ap-southeast-2.amazonaws.com/dev/CallHistory/' + sessionStorage.getItem("c_user"))
            fetch(config.CALLHISTORYAPI + sessionStorage.getItem("c_user"))
                .then(res => res.json())
                .then((data) => {
                    console.log(data)
                    const { callHistoryDetails } = data;
                    const rowdata: any = [];

                    callHistoryDetails.forEach((element) => {
                        if (callHistoryDetails) {

                            rowdata.push(this.createData(element["firstName"], element["lastName"], element["callHistoryDetails"]["endpoint"]["phoneNumber"], element["callHistoryDetails"]["type"],
                                element["callHistoryDetails"]["state"]["timestamp"]));
                        }
                    });
                    this.setState({
                        history: rowdata,
                        // loaded: true
                    });

                })
                .catch(console.log);
        }

    }


    public render() {

        const historyData = this.state.history;
        const loaded = this.state.loaded;
        let styletoadd = ""
        if (!this.props.stylesetting) {
            styletoadd = "content-wrapper-td"
        } else {
            styletoadd = this.props.stylesetting
        }


        return (
            <div className="history-wrapper">
                {!loaded && (<InterfaceLoader />)}
                <div className='component-title topoffset'>Call History</div>
                <InterfaceHeader user={this.props.user} pagename="Call History" />
                <div className={styletoadd}>
                    <CallHistory dataTable={historyData}></CallHistory>
                </div>
                {this.props.user.permissions.navigation.active && this.props.navbardisplay && (<nav>
                    <InterfaceSidebar user={this.props.user} />
                </nav>
                )}
            </div>

        )

    }

}
