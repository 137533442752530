import React from 'react';
import cwlogo from "../../../css/images/Cloudwave-animated.gif";
//import { Link } from 'react-router-dom';



export default class InterfaceLoader extends React.Component {

  public render() {

    return (
       <div className="loader">
        <div className="loader-image">
          <img src={cwlogo} alt="client logo" />
          <div className="laoder-text">Loading...</div>
        </div>
      </div>

    );
  }
}
