import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 500,
			backgroundColor: theme.palette.background.paper,
			borderRadius: 5,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

export default function CreateContact(props: any) {

	const dataCont = {
		id: '',
		firstName: '',
		lastName: '',
		phoneNumber: '+',
		numberType: 'mobile',
		companies: ''
	}

	const classes = useStyles();

	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(false);

	const [firstNameVl, setFirstNameVl] = React.useState({req: '', min: '', pure: true});
	const [lastNameVl, setLastNameVl] = React.useState({req: '', min: '', pure: true});
	const [phoneNumbVl, setPhoneNumbNameVl] = React.useState({req: '', num: '', pure: true});
	const [sendStatus, setSendStatus] = React.useState({class: '', mes: ''});

	const handleOpen = () => {
		setOpen(true);
	}

	const getNumberType = (event: React.ChangeEvent<HTMLInputElement>) => {
		dataCont.numberType = event.target.value;
	}

	const getFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
		const data = event.target.value;

		setFirstNameVl({req: '', min: '', pure: false});

		if (!data) {
			setFirstNameVl({req: ' field_empty', min: '', pure: false});
			return false;
		}

		if (data && data.length < 3) {
			setFirstNameVl({req: '', min: ' field_min', pure: false});
			return false;
		}
	}


	const getLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
		const data = event.target.value;
		setLastNameVl({req: '', min: '', pure: false});

		if (!data) {
			setLastNameVl({req: ' field_empty', min: '', pure: false});
			return false;
		}

		if (data && data.length < 3) {
			setLastNameVl({req: '', min: ' field_min', pure: false});
			return false;
		}
	}


	const checkPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
		let data = event.target.value;
		data = data.replace(/\+/g, '');
		setPhoneNumbNameVl({req: '', num: '', pure: false});

		if (!data) {
			setPhoneNumbNameVl({req: ' field_empty', num: '', pure: false});
			return false;
		}

		if (data && !data.match(/^\d+$/g)) {
			setPhoneNumbNameVl({req: '', num: ' field_numb', pure: false});
			return false;
		}

		event.target.value = '+' + data;
	}

	/* const handleSave = () => {
		console.log('save event handler');
	} */

	const formSubmit = (event) => {
		event.preventDefault();
		dataCont.firstName = event.target.elements.firstName.value;
		dataCont.lastName = event.target.elements.lastName.value;
		dataCont.id = dataCont.lastName + dataCont.firstName;
		dataCont.phoneNumber = event.target.elements.phoneNumber.value;
		dataCont.numberType = event.target.elements.typePhone.value;
		dataCont.companies = 'Company 3';

		if (firstNameVl.pure) setFirstNameVl({req: ' field_empty', min: '', pure: true});
		if (lastNameVl.pure) setLastNameVl({req: ' field_empty', min: '', pure: true});
		if (phoneNumbVl.pure) setPhoneNumbNameVl({req: ' field_empty', num: '', pure: true});

		if (!firstNameVl.req && !firstNameVl.min && !firstNameVl.pure &&
			!lastNameVl.req && !lastNameVl.min && !lastNameVl.pure &&
			!phoneNumbVl.req && !phoneNumbVl.num && !phoneNumbVl.pure) {
				props.openCont(dataCont);
				setSendStatus({class: ' add-cont__mes_suc', mes: 'Contact added'});
				setTimeout( () => {
					setOpen(false);
					setSendStatus({class: '', mes: ''});
					resetFormVal();
				}, 1500 );
		} else {
			setSendStatus({class: ' add-cont__mes_val', mes: 'The form is not valid'});
		}

		setTimeout( () => {
			setSendStatus({class: '', mes: ''});
		}, 2500 );
	}

	const handleClose = (event) => {
		event.preventDefault();
		setOpen(false);
		resetFormVal();
	};

	const resetFormVal = () => {
		setFirstNameVl({req: '', min: '', pure: true});
		setLastNameVl({req: '', min: '', pure: true});
		setPhoneNumbNameVl({req: '', num: '', pure: true});
	}

	return (
		<div>
			<button type="button" className="btn_table btn-primary searchbutton" onClick={handleOpen}>
				+
			</button>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={open}
				onClose={handleClose}
			>
				<div style={modalStyle} className={classes.paper}>
					<h2 id="simple-modal-title" className="form-title">Do you want to add personal contact?</h2>
					<div id="simple-modal-description">
						<form className="form form_cont" noValidate autoComplete="on" onSubmit={formSubmit}>
							<div className="form-wrap__field">
								<div className={"form-field-cont" + firstNameVl.req + firstNameVl.min}>
									<p className="form-field-cont__text">First name</p>
									<input
										type="text"
										onBlur={getFirstName}
									  name="firstName"
										className="form-field__inp"
									/>
									<p className="form-field__valid form-field__valid_empty">The field
										cannot be empty</p>
									<p className="form-field__valid form-field__valid_min">Must be at
										least three letters</p>
								</div>
								<div className={"form-field-cont" + lastNameVl.req + lastNameVl.min}>
									<p className="form-field-cont__text">Last name</p>
									<input type="text" onBlur={getLastName}
									       name="lastName"
									       className="form-field__inp" />
									<p className="form-field__valid form-field__valid_empty">The field
										cannot be empty</p>
									<p className="form-field__valid form-field__valid_min">Must be at
										least three letters</p>
								</div>
								<div className={"form-field-cont" + phoneNumbVl.req + phoneNumbVl.num}>
									<p className="form-field-cont__text">Number</p>
									<input type="text" defaultValue={dataCont.phoneNumber}
									       name="phoneNumber"
									       onBlur={checkPhoneNumber}
									       className="form-field__inp" />
									<p className="form-field__valid form-field__valid_empty">The field
										cannot be empty</p>
									<p className="form-field__valid form-field__valid_numb">There can
										only be numbers</p>
								</div>
							</div>
							<div className="form-wrap__radio">
								<label className="form__lab">
									<input type="radio" name="typePhone"
									       className="form-lab__inp"
									       defaultChecked = {dataCont.numberType === 'mobile'}
									       hidden value="mobile"
									       onChange={getNumberType} />
									<span className="form-lab__mark"/>
									<span className="form-field-cont__text">Mobile phone</span>
								</label>
								<label className="form__lab">
									<input type="radio" name="typePhone"
									       className="form-lab__inp"
									       hidden value="home"
									       onChange={getNumberType} />
									<span className="form-lab__mark"/>
									<span className="form-field-cont__text">Home Phone</span>
								</label>
								<label className="form__lab">
									<input type="radio" name="typePhone"
									       className="form-lab__inp"
									       hidden value="work"
									       onChange={getNumberType} />
									<span className="form-lab__mark"/>
									<span className="form-field-cont__text">Work phone</span>
								</label>
							</div>
							<div className="form-wrap__text">
								<p className="form-text__alert">E.164 format is required for
									extemal phone-number based</p>
								<p className="form-text__desc">Phone numbers must use E.164 format,
									which can have a maximum of fifteen digits...</p>
								<p className={'add-cont__mes' + sendStatus.class}>{sendStatus.mes}</p>
							</div>
							<div className="form-wrap__btn">
								<button type='submit' className="btn_form btn-primary">Save</button>
								<button type='button' className="btn_form btn-danger" onClick={handleClose}>Cancel</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</div>
	);
}
