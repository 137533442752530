import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { 
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography as Text,
    Divider,
    Grid,
    Link,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Contacts } from '@material-ui/icons';
import '../../../../../css/methods.scss';
import { config } from "../../../../core/config/config";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: '100%',
      margin: theme.spacing(2),
      padding: 24
    },
    media: {
      height: 190,
    },
    contactIcon: {
      color: '#00afe9',
      backgroundColor: '#000000',
    },
    content: {
        backgroundColor: '#fff'
    },
    label: {
        paddingLeft: 5,
        paddingTop: 10,
        paddingBottom: 5,
        paddingRight: 5,
        color: '#808080'
    },
    value: {
        color: '#696969',
        paddingLeft: 5,
        fontWeight: 600,
    },
    heading: {
        color: '#696969',
        fontWeight: 550,
    },
    subHeading: {
        color: '#808080'
    }
  }), 
);

function Contact(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  const [contactFetched,setContactFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [contact, setContact] = useState({
    id: 0,
    is_a: "",
    username: "",
    url: "",
    email: "",
    organisation: "",
    phone_number: "",
    mobile_phone_number: ""
  });

  props = props.props;

  if(sessionStorage.getItem("onConnecting") === "false" && phoneNum !== "") {
    setPhoneNum("");
    setFetching(false);
    setLoading(false);
  }
  
  if(sessionStorage.getItem("onConnecting") === "false" && contactFetched) {
    setContactFetched(false);
    setContact({
      id: 0,
      is_a: "",
      username: "",
      url: "",
      email: "",
      organisation: "",
      phone_number: "",
      mobile_phone_number: ""
    })
  }

  function queryContact(access_token) {
    if(!fetching) {
      setFetching(true);
      fetch(config.COSMOSAPI.API_URL + '/phone_number?value=' + props.phoneNum, {
        method: 'GET',
        headers: {
          "Authorization" : "Bearer " + access_token
        }
      })
      .then(res => res.json())
      .then((result) => {
        setContact(result);
        setPhoneNum(props.phoneNum);
        setContactFetched(result.hasOwnProperty('id'));
      })
      .catch((error) => {
        console.log("Error occurred. Details: ", error);
        setContactFetched(false);
      })
      .finally(() => {
        setFetching(false);
        setLoading(false);
      })
    }
  }

  async function getAccessToken() {
    let promise = new Promise((resolve, reject) => {
      fetch(config.COSMOSAPI.TOKEN_URL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
            grant_type: "client_credentials",
            client_id: config.COSMOSAPI.CLIENT_ID, 
            client_secret: config.COSMOSAPI.SECRET_KEY,
            audience: "https://api.cosmos-cms.com.au"
        })
      })  
      .then(res => {
        return res.json();
      })
      .then((result) => {
        document.cookie = "cinfo=" + result.access_token +";SameSite=Lax";
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
    });
    let result = await promise;
    return result;
  }

  if(phoneNum !== props.phoneNum && props.phoneNum !== null && props.phoneNum !== "" && props.phoneNum !== undefined && !loading) {
    setLoading(true);
    setContactFetched(false);
    
    let access_token = document.cookie.match(new RegExp('cinfo=([^;]+)'));
    let decoded;
    let exp;

    if(access_token !== null) { // use previous access token in cookies
      decoded = jwt_decode(access_token[1]); // decode jwt
      exp = decoded.exp; // get exp value
      if(exp < Date.now()) { // check if not expired, if not expired, use
        queryContact(access_token[1]);
      } else { // expired, get new and query
        getAccessToken().then((result: any) => {
          queryContact(result.access_token);
        });
      }
    } else { // get access token and then query contact
      getAccessToken().then((result: any) => {
        queryContact(result.access_token);
      });
    }
  }
  
  return (
    <Card className={classes.card} elevation={4}>
      <CardHeader
        avatar={
          loading ? (
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          ) : (
            <Avatar
              alt="Contact"
              className={classes.contactIcon}
            >
              <Contacts />
            </Avatar>
          )
        }
        title={
          loading ? (
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
          ) : (
            <Text className={classes.heading}>{contactFetched ? contact.username:"No COSMOS information available"}</Text>
          )
        }
        subheader={loading ? <Skeleton animation="wave" height={10} width="40%" /> : <Text className={classes.subHeading}>{contact.is_a}</Text> }
      />
      {loading && (
        <Skeleton animation="wave" variant="rect" className={classes.media} />
      )}
      <Divider />
      <CardContent>
        {loading ? (
          <React.Fragment>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </React.Fragment>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
                <Text className={classes.label}>Organisation: </Text>
                <Text className={classes.value}>{contact.organisation}</Text>
                <Text className={classes.label}>Email: </Text>
                <Text className={classes.value}>{contact.email}</Text>
                <Text className={classes.label}>Phone: </Text>
                <Text className={classes.value}>{contact.phone_number}</Text>
            </Grid>
            <Grid item xs={6}>
                <Text className={classes.label}>Mobile: </Text>
                <Text className={classes.value}>{contact.mobile_phone_number}</Text>
                <Text className={classes.label}>Profile URL: </Text>
                <Link className={classes.value} target='blank' href={contact.url}>{contactFetched ? "Click here to view in COSMOS":""}</Link>
            </Grid>
          </Grid> 
        )}
      </CardContent>
    </Card>
  );
}

export default function Cosmos(props) {
  return (
    <div>
      <Contact props={props}/>
    </div>
  );
}
