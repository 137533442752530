import React, { createContext } from 'react';
import { config } from '../core/config/config';
// import { config } from '../core/config/config';

interface IProps {

 }

 interface IStates {
   loaded: boolean
   username: any
   id: any
   avatar: any
 }


const UserContext = createContext({
  username: '',
  id: '',
  avatar: ''

});

export class UserProvider extends React.Component<IProps, IStates> {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      username: "Loading",
      id: '',
      avatar: ''
    };
  }

  public componentDidMount() {

    // fetch(
    //   'https://api.jsonbin.io/b/5f18f373c1edc466175c916b', {
    //     method: 'GET',
    //     headers: new Headers({
    //      'secret-key': '$2b$10$wHrEQEUK3m4WJ78pezqkg.QpgBpcLVpri3EO3fA2Zq/D.kSFDE9xy',
    //      })
    //   }
    // )
    fetch(config.USERAPI + sessionStorage.getItem("c_user"))
      .then(res => res.json())
      .then(data => {
        const loaded = this.state.loaded;

        this.setState({
          loaded: !loaded,
          username: data["configBlob"].username,
          id: data["configBlob"].id,
          avatar: data["configBlob"].avatar
        });


      })
      .catch(console.log);

  }

  public render() {

    const loaded = this.state.loaded;

    return (
      <div>
      {loaded && (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
      )}
      </div>
    );
  }
}

export const UserConsumer = UserContext.Consumer, ContextObject = UserContext ;
