import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import copy from 'copy-to-clipboard';

import '../../../../css/brand/test/interface/share.scss'

export default function Share (props:any) {
  const {closeFunc} = props
  const {dashboardID} = props


  const valueChange = (event) => {
      /* import names for prepop list */
  };





  const url = window.location.href + "/" + dashboardID;

  const copyText = () => {
    copy(url);
  };


  return (
    <div className="pop-addDB">

      <form className="form-wrapper" noValidate autoComplete="off">
        <h2>Share</h2>
        <div className="add-db-item">
      <TextField id="name" label="Enter Names" onChange={valueChange} placeholder="Enter Names" />
      </div>
      <div className="add-db-item">

      </div>
      <div className="add-db-item">
        <div className="share-link">{url}</div><div className="share-button-array"><Button onClick={copyText}>Copy link</Button></div>
      </div>



        <div className="edit-widget-button-array"><Button onClick={closeFunc}>Cancel</Button></div>
      </form>
    </div>
  );
}
