import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ShareIcon from '@material-ui/icons/Share';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import '../../../../css/brand/test/interface/actionMenu.scss'
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { FormControl, InputLabel, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      "& option": {
        background: theme.palette.background.paper
      }
    }
  }),
);

export default function ActionMenu (props:any) {

  const classes = useStyles();

  const { actionMenuSelect, userperms, dashboards, currentDashboardSelect } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
      const { myValue } = event.currentTarget.dataset;
      // console.log(myValue)
      actionMenuSelect(myValue);
      setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [board, setBoard] = React.useState({id: 'na', name: '', index: 0});
  useEffect(() => {
    setBoard(board);
    currentDashboardSelect(board.index);
  }, 
  // eslint-disable-next-line 
  [board])

  const handleBoardSelect = (event) => {
    const currentIndex = dashboards.map(x => x.id).indexOf(event.target.value);
    setBoard({
      id: dashboards[currentIndex].id,
      name: dashboards[currentIndex].name,
      index: currentIndex
    })    
    // console.log(currentIndex);
  }


  return (
    <div className="action-menu">
    <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"

        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large"  />
      </Button>
        <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="action-menu-drop"

      >
        <FormControl className={classes.formControl}>
          <InputLabel id="board-select" style={{'color': 'white'}}>Select Board</InputLabel>
          <Select
            native
            id="board-select"
            value={board.id || ''}
            onChange={event => handleBoardSelect(event)}
            inputProps={{ name: 'board', id: 'board-select' }}
            // className={classes.select}
            style={{color: 'white', marginLeft: '2px'}}
          >
            {[...[{id: 'na', name: ''}], ...dashboards].map(dashboard => {
                return <option style={{color: 'black'}} key={dashboard.id} value={dashboard.id}>{dashboard.name}</option>
            })}
          </Select>
        </FormControl>

        {userperms.adddb && (<StyledMenuItem>
        <ListItemText primary="New Board"  onClick={handleSelect} data-my-value="1" />
          <ListItemIcon>
            <DashboardIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>)}

        {userperms.share && (<StyledMenuItem>
        <ListItemText primary="Share" onClick={handleSelect} data-my-value="3" />
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>)}

        {userperms.duplicate && (  <StyledMenuItem>
       <ListItemText primary="Duplicate" onClick={handleSelect} data-my-value="4"/>
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>)}

        {userperms.edit && (<StyledMenuItem>
        <ListItemText primary="Edit Board" onClick={handleSelect} data-my-value="5"/>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>)}

        {userperms.addwidgets && (<StyledMenuItem>
        <ListItemText primary="Add Widget" onClick={handleSelect} data-my-value="6" />
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>)}

        {userperms.delete && dashboards.length > 1 && (<StyledMenuItem>
        <ListItemText primary="Delete"  onClick={handleSelect} data-my-value="7"/>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>)}

        </StyledMenu>
    </div>
  );
}
